import { Quantity, QuantityDto } from './quantity';
import { QuantityName } from './quantity-names';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface MeasurementDto<T extends QuantityName> {
  name: string;
  quantity: QuantityDto<T>;
}

export class Measurement<T extends QuantityName> {
  // prettier-ignore
  constructor(
    public readonly name: string,
    public readonly quantity: Quantity<T>
  ) {}

  static fromDto<T extends QuantityName>(dto: MeasurementDto<T>): Measurement<T> {
    return new Measurement(dto.name, Quantity.fromDto(dto.quantity));
  }

  toDto(): MeasurementDto<T> {
    return { name: this.name, quantity: this.quantity.toDto() };
  }
}
