import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FoodListComponent } from './food/food-list/food-list.component';
import { JournalComponent } from './journal/journal-component/journal.component';
import { LockerRoomComponent } from './locker-room/locker-room.component';
import { PreferencesComponent } from './preferences/preferences.component';

export const appRoutes: Route[] = [
  { path: '', redirectTo: 'journal', pathMatch: 'full' },
  { path: 'journal', component: JournalComponent, canActivate: [MsalGuard] },
  { path: 'food', component: FoodListComponent, canActivate: [MsalGuard] },
  { path: 'locker-room', component: LockerRoomComponent, canActivate: [MsalGuard] },
  { path: 'preferences', component: PreferencesComponent, canActivate: [MsalGuard] },
];
