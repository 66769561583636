import { Measurement, MeasurementDto } from '../quantities/measurement';
import { QuantityName } from '../quantities/quantity-names';

export interface IngredientDto {
  foodId: string;
  parts: number;
  servingSize: MeasurementDto<QuantityName>;
}

export class Ingredient {
  constructor(
    public readonly foodId: string,
    public readonly parts: number,
    public readonly servingSize: Measurement<QuantityName>
  ) {}

  static fromDto(dto: IngredientDto): Ingredient {
    return new Ingredient(dto.foodId, dto.parts, Measurement.fromDto(dto.servingSize));
  }

  toDto(): IngredientDto {
    return { foodId: this.foodId, parts: this.parts, servingSize: this.servingSize.toDto() };
  }
}
