<mat-toolbar>
  <div class="w-80 ml-2">{{ longDate$ | async }}</div>

  <button class="w-32 mx-2" mat-raised-button (click)="previousDay()">
    <div class="flex items-center">
      <mat-icon>navigate_before</mat-icon>
      <span class="mt-[2px]">Previous</span>
    </div>
  </button>

  <button class="w-32 mx-2" mat-raised-button (click)="nextDay()">
    <div class="flex items-center">
      <span class="mt-[2px]">Next</span>
      <mat-icon>navigate_next</mat-icon>
    </div>
  </button>

  <ng-container *ngIf="(isToday$ | async) === false">
    <button class="w-32 mx-2" mat-raised-button (click)="today()">
      <div class="flex items-center">
        <span class="mt-[2px]">Today</span>
      </div>
    </button>
  </ng-container>
</mat-toolbar>

<div class="flex flex-col h-[calc(100vh-120px)]">
  <!-- top row -->
  <div class="flex h-[256px]">
    <!-- Daily Nutrition Goals -->
    <div class="flex basis-1/2 h-full pt-4 pr-2 pb-2 pl-4">
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title> Daily Nutrition Goals </mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center">
          <lean-nutrition-goals></lean-nutrition-goals>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Daily Calorie Breakdown -->
    <div class="flex basis-1/2 pt-4 pr-4 pb-2 pl-4">
      <mat-card class="w-full">
        <mat-card-header>
          <mat-card-title>Daily Calorie Breakdown</mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center">
          <lean-calory-breakdown></lean-calory-breakdown>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- bottom row -->
  <div class="flex grow">
    <!-- Nutrition Log -->
    <div class="flex basis-3/4 pt-2 pr-2 pb-4 pl-4">
      <mat-card class="w-full">
        <mat-card-header class="!pb-4 border-b border-b-neutral-500">
          <mat-card-title>Nutrition Log</mat-card-title>
        </mat-card-header>
        <!-- <mat-divider></mat-divider> -->
        <mat-card-content>
          <lean-nutrition-log></lean-nutrition-log>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- right colum -->
    <div class="flex flex-col basis-1/4 pt-2 pr-4 pb-4 pl-2">
      <!-- Water Tracker -->
      <div class="flex basis-1/3 pb-2">
        <mat-card class="w-full">
          <mat-card-header>
            <mat-card-title>Water Tracker</mat-card-title>
          </mat-card-header>
          <mat-card-content class="text-center">
            <lean-water-tracker></lean-water-tracker>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Training -->
      <div class="flex basis-2/3">
        <mat-card class="w-full pt-2">
          <mat-card-header>
            <mat-card-title>
              Training
              <button
                mat-icon-button
                class="!absolute top-2 right-3"
                [matMenuTriggerFor]="menu"
                aria-label="Toggle menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item>Expand</button>
                <button mat-menu-item>Remove</button>
              </mat-menu>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="text-center">
            <lean-training-sessions></lean-training-sessions>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
