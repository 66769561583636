import { QuantityDefinition } from './quantity-types';

export type LengthType = {
  name: 'Length';
  baseUnit: 'm';
  unitKeys: 'mm' | 'cm';
};

export type Length = LengthType['name'];

export const lengthDefinition: QuantityDefinition<Length> = {
  name: 'Length',
  baseUnit: {
    symbol: 'm',
    singularName: 'metre',
    pluralName: 'metres',
  },
  units: {
    cm: {
      symbol: 'cm',
      singularName: 'centimetre',
      pluralName: 'centimetres',
      fromUnitToBaseFunc: (x: number): number => x / 1e2,
      fromBaseToUnitFunc: (x: number): number => x * 1e2,
    },
    mm: {
      symbol: 'mm',
      singularName: 'millimetre',
      pluralName: 'millimetres',
      fromUnitToBaseFunc: (x: number): number => x / 1e3,
      fromBaseToUnitFunc: (x: number): number => x * 1e3,
    },
  },
};
