<mat-form-field class="w-full" subscriptSizing="dynamic">
  <mat-label>Food</mat-label>
  <input
    matInput
    type="text"
    class="!text-sm"
    [matAutocomplete]="auto"
    [formControl]="pickerControl"
    placeholder="Start typing to filter"
    (focus)="filter($event)"
    (input)="filter($event)"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    requireSelection
    panelWidth="48rem"
    [displayWith]="displayWithFn"
    (optionSelected)="onFoodSelected($event.option.value)"
  >
    <mat-option *ngFor="let food of filteredFood$ | async; trackBy: trackByFn" [value]="food">
      <span>{{ food.description }}</span> |
      <span>Energy: {{ food.energy.toString() }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
