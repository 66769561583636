import { Energy, Mass, Quantity, QuantityDto, energy } from '../quantities';
import { MeasurementDto } from '../quantities/measurement';
import { UUID } from '../utilities';
import { Ingredient, IngredientDto } from './ingredient';
import { Nutrients, NutrientsDto } from './nutrients';
import { ServingSize } from './serving-size';

// @CosmosPartitionKey('userId')
export class FoodDto {
  /** Cosmos DB assigned uuid */
  id!: UUID;
  userId!: UUID;
  description!: string;
  brand?: string;
  source?: string;
  energy?: QuantityDto<Energy>;
  nutrients?: NutrientsDto;
  servingSizes?: MeasurementDto<Mass>[];
  ingredients?: IngredientDto[];
}

export class Food {
  constructor(
    /** Cosmos DB assigned uuid */
    public readonly id: UUID = '',
    public readonly userId: UUID = '',
    public description: string = ''
  ) {}

  brand?: string;
  source?: string;
  energy = energy(0, 'kcal');
  nutrients: Nutrients = new Nutrients();
  servingSizes: ServingSize[] = [];
  ingredients: Ingredient[] = [];

  get isNew(): boolean {
    return this.id === '';
  }

  static fromDto(dto: FoodDto): Food {
    const model = new Food(dto.id, dto.userId, dto.description);
    model.brand = dto.brand;
    model.source = dto.source;

    if (dto.energy) model.energy = Quantity.fromDto(dto.energy);
    if (dto.nutrients) model.nutrients = Nutrients.fromDto(dto.nutrients);
    if (dto.servingSizes)
      model.servingSizes = dto.servingSizes?.map((ss) => ServingSize.fromDto(ss));
    if (dto.ingredients) model.ingredients = dto.ingredients?.map((i) => Ingredient.fromDto(i));
    return model;
  }

  toDto(): FoodDto {
    return {
      id: this.id,
      userId: this.userId,
      description: this.description,
      brand: this.brand,
      source: this.source,
      energy: this.energy.toDto(),
      nutrients: this.nutrients.toDto(),
      servingSizes: this.servingSizes.map((ss) => ss.toDto()),
      ingredients: this.ingredients.map((i) => i.toDto()),
    };
  }
}
