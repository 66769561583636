import { Observable, Subscriber } from 'rxjs';

export function readAsDataURL(blob: Blob): Observable<string> {
  return new Observable((subscriber) => {
    const reader = getFileReader(subscriber);
    return reader.readAsDataURL(blob);
  });
}

export function readAsText(blob: Blob): Observable<string> {
  return new Observable((subscriber) => {
    const reader = getFileReader(subscriber);
    return reader.readAsText(blob);
  });
}

function getFileReader(subscriber: Subscriber<string>) {
  const reader = new FileReader();

  reader.onerror = (err) => subscriber.error(err);
  reader.onabort = (err) => subscriber.error(err);
  reader.onload = () => subscriber.next(reader.result as string);
  reader.onloadend = () => subscriber.complete();
  return reader;
}
