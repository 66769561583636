import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { JournalEntry, volume } from '@lean-life/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable, map, take } from 'rxjs';
import { JournalState } from '../journal.state';
import { WaterTrackerService } from './water-tracker.service';

const defaultQuantity = volume(250, 'ml');

@UntilDestroy()
@Component({
  selector: 'lean-water-tracker',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './water-tracker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterTrackerComponent {
  private service = inject(WaterTrackerService);

  @Select(JournalState.current) currentEntry$!: Observable<JournalEntry>;

  waterQuantity$ = this.currentEntry$.pipe(
    map((entry) => {
      const quantity = entry?.water ?? volume(0, 'ml');
      return quantity.toString();
    })
  );

  increment() {
    this.service.increment(defaultQuantity).pipe(take(1), untilDestroyed(this)).subscribe();
  }

  decrement() {
    this.service.decrement(defaultQuantity).pipe(take(1), untilDestroyed(this)).subscribe();
  }
}
