<!-- description -->
<mat-form-field class="w-full">
  <mat-label>Description</mat-label>
  <input matInput value="{{ food.description }}" disabled />
</mat-form-field>

<!-- brand -->
<mat-form-field class="w-4/5">
  <mat-label>Brand</mat-label>
  <input matInput value="{{ food.brand }}" disabled />
</mat-form-field>

<!-- source -->
<div class="flex items-baseline">
  <mat-form-field class="w-4/5 mr-2">
    <mat-label>Source</mat-label>
    <input matInput value="{{ food.source }}" disabled />
  </mat-form-field>
  <button mat-icon-button (click)="openSourceUrl()"><mat-icon>link</mat-icon></button>
</div>

<!-- nutritional information -->
<div class="flex h-16 w-2/3 mb-5 border-b border-b-gray-400">
  <div class="flex items-center h-full grow text-xl">Nutritional Information</div>
  <div class="flex items-center h-full w-64">
    <lean-serving-size-picker
      [food]="food"
      (servingSizeSelected)="onServingSizeSelected($event)"
    ></lean-serving-size-picker>
  </div>
</div>

<div class="flex flex-col h-full">
  <!-- energy -->
  <mat-form-field class="w-32">
    <mat-label>Energy</mat-label>
    <input matInput value="{{ energy.value | number : '1.0-0' }}" disabled />
    <span matTextSuffix>{{ energy.unit }}</span>
  </mat-form-field>

  <!-- protein -->
  <mat-form-field class="w-32">
    <mat-label>Protein</mat-label>
    <input matInput value="{{ nutrients.protein.value | number : '1.2-2' }}" disabled />
    <span matTextSuffix>{{ nutrients.protein.unit }}</span>
  </mat-form-field>

  <!-- carbs -->
  <div class="flex">
    <!-- carbs -->
    <mat-form-field class="w-32 mr-4">
      <mat-label>Carbs</mat-label>
      <input matInput value="{{ nutrients.carbs.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.carbs.unit }}</span>
    </mat-form-field>

    <!-- fibre -->
    <mat-form-field class="w-32 ml-4">
      <mat-label>Fibre</mat-label>
      <input matInput value="{{ nutrients.fibre.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.fibre.unit }}</span>
    </mat-form-field>

    <!-- sugar -->
    <mat-form-field class="w-32 ml-4">
      <mat-label>Sugar</mat-label>
      <input matInput value="{{ nutrients.sugar.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.sugar.unit }}</span>
    </mat-form-field>
  </div>

  <!-- fat -->
  <div class="flex">
    <!-- fat -->
    <mat-form-field class="w-32 mr-4">
      <mat-label>Fat</mat-label>
      <input matInput value="{{ nutrients.fat.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.fat.unit }}</span>
    </mat-form-field>

    <!-- sats -->
    <mat-form-field class="w-32 ml-4">
      <mat-label>Sats</mat-label>
      <input matInput value="{{ nutrients.sats.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.sats.unit }}</span>
    </mat-form-field>

    <!-- mono -->
    <mat-form-field class="w-32 ml-4">
      <mat-label>Mono</mat-label>
      <input matInput value="{{ nutrients.mono.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.mono.unit }}</span>
    </mat-form-field>

    <!-- poly -->
    <mat-form-field class="w-32 ml-4">
      <mat-label>Poly</mat-label>
      <input matInput value="{{ nutrients.poly.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.poly.unit }}</span>
    </mat-form-field>

    <!-- trans -->
    <mat-form-field class="w-32 ml-4">
      <mat-label>Trans</mat-label>
      <input matInput value="{{ nutrients.trans.value | number : '1.2-2' }}" disabled />
      <span matTextSuffix>{{ nutrients.trans.unit }}</span>
    </mat-form-field>
  </div>
</div>
