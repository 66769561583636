import { Injectable } from '@angular/core';
import { Profile } from '@lean-life/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearProfile, SetLoggedIn, SetProfile, ToggleSidenav } from './app.actions';

interface AppStateModel {
  loggedIn: boolean;
  profile?: Profile;
  sidenavOpen: boolean;
}

@State<AppStateModel>({
  name: 'appState',
  defaults: {
    loggedIn: false,
    sidenavOpen: true,
  },
})
@Injectable()
export class AppState {
  @Selector() static loggedIn(state: AppStateModel) {
    return state.loggedIn;
  }

  @Selector() static profile(state: AppStateModel) {
    return state.profile;
  }

  @Selector() static userId(state: AppStateModel) {
    return state.profile?.id;
  }

  @Selector() static sidenavOpen(state: AppStateModel) {
    return state.loggedIn && state.sidenavOpen;
  }

  @Action(SetLoggedIn) setLoggedIn(ctx: StateContext<AppStateModel>, action: SetLoggedIn) {
    ctx.patchState({ loggedIn: action.loggedIn });
  }

  @Action(SetProfile) setProfile(ctx: StateContext<AppStateModel>, action: SetProfile) {
    ctx.patchState({ profile: action.profile });
  }

  @Action(ClearProfile) clearProfile(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ profile: undefined });
  }

  @Action(ToggleSidenav) ToggleSidenav(ctx: StateContext<AppStateModel>) {
    const sidenavOpen = ctx.getState().sidenavOpen;
    ctx.patchState({ sidenavOpen: !sidenavOpen });
  }
}
