<div class="py-6">
  <div class="border border-solid border-neutral-600 rounded">
    <table mat-table [dataSource]="dataSource" class="w-full">
      <!-- description -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-xl">{{ mealtime }}</th>

        <td mat-cell *matCellDef="let item; index as i">
          <ng-container *ngIf="i !== (selectedIndex$ | async)">
            {{ item.food.description }}
          </ng-container>
          <ng-container *ngIf="i === (selectedIndex$ | async)">
            <lean-food-picker [formControl]="foodPicker"></lean-food-picker>
          </ng-container>
        </td>

        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- portion -->
      <ng-container matColumnDef="portion">
        <th mat-header-cell *matHeaderCellDef class="w-32 !text-end">
          <span>Portion</span>
        </th>

        <td mat-cell *matCellDef="let item; index as i" class="!text-end">
          <ng-container *ngIf="i !== (selectedIndex$ | async)">
            {{ item.portion | number }}
          </ng-container>
          <ng-container *ngIf="i === (selectedIndex$ | async)">
            <mat-form-field class="density-3 w-24 text-right" subscriptSizing="dynamic">
              <input
                type="number"
                min="0"
                matInput
                [formControl]="portionControl"
                class="!text-sm"
              />
            </mat-form-field>
          </ng-container>
        </td>

        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- servingSize -->
      <ng-container matColumnDef="servingSize">
        <th mat-header-cell *matHeaderCellDef class="w-48">
          <span>Serving Size</span>
        </th>
        <td mat-cell *matCellDef="let item; index as i">
          <ng-container *ngIf="i !== (selectedIndex$ | async)">
            {{ item.servingSize.display }}
          </ng-container>
          <ng-container *ngIf="i === (selectedIndex$ | async)">
            <lean-serving-size-picker
              class="density-3 w-40 text-right"
              [food]="item.food"
              [servingSize]="item.servingSize"
              (servingSizeSelected)="onServingSizeSelected($event)"
            >
            </lean-serving-size-picker>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef class="!text-end">Meal Totals:</td>
      </ng-container>

      <!-- energy -->
      <ng-container matColumnDef="energy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 !text-end">
          <span>Energy</span>
        </th>
        <td mat-cell *matCellDef="let item" class="!text-end">
          {{ item.energy.value | number : '1.0-0' }}
        </td>
        <td mat-footer-cell *matFooterCellDef="let item" class="!text-end">
          {{ meal.energy.value | number : '1.0-0' }}
        </td>
      </ng-container>

      <!-- protein -->
      <ng-container matColumnDef="protein">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 !text-end">
          <span>Protein</span>
        </th>
        <td mat-cell *matCellDef="let item" class="!text-end">
          {{ item.nutrients.protein.value | number : '1.2-2' }}
        </td>
        <td mat-footer-cell *matFooterCellDef="let item" class="!text-end">
          {{ meal.nutrients.protein.value | number : '1.2-2' }}
        </td>
      </ng-container>

      <!-- carbs -->
      <ng-container matColumnDef="carbs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 !text-end">
          <span>Carbs</span>
        </th>
        <td mat-cell *matCellDef="let item" class="!text-end">
          {{ item.nutrients.carbs.value | number : '1.2-2' }}
        </td>
        <td mat-footer-cell *matFooterCellDef="let item" class="!text-end">
          {{ meal.nutrients.carbs.value | number : '1.2-2' }}
        </td>
      </ng-container>

      <!-- fat -->
      <ng-container matColumnDef="fat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20 !text-end">
          <span>Fat</span>
        </th>
        <td mat-cell *matCellDef="let item" class="!text-end">
          {{ item.nutrients.fat.value | number : '1.2-2' }}
        </td>
        <td mat-footer-cell *matFooterCellDef="let item" class="!text-end">
          {{ meal.nutrients.fat.value | number : '1.2-2' }}
        </td>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef="let item; index as i" class="w-32 !text-end">
          <button mat-icon-button [disabled]="isEditing$ | async" (click)="onAddClick()">
            <mat-icon>add</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let item; index as i" class="!text-end">
          <ng-container *ngIf="i !== (selectedIndex$ | async)">
            <button mat-icon-button [disabled]="isEditing$ | async" (click)="onEditClick(i)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button [disabled]="isEditing$ | async" (click)="onDeleteClick(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>

          <ng-container *ngIf="i === (selectedIndex$ | async)">
            <button mat-icon-button (click)="onSaveClick()">
              <mat-icon>save</mat-icon>
            </button>
            <button mat-icon-button (click)="onCancelClick()">
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let item; columns: displayedColumns"
        class="hover:cursor-pointer"
        (click)="onRowClick()"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns"
        class="border-t border-neutral-600"
      ></tr>
    </table>
  </div>
</div>
