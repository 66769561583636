<div class="flex flex-col h-full">
  <div mat-dialog-content>
    <mat-tab-group
      disablePagination="true"
      disableRipple="true"
      dynamicHeight="true"
      preserveContent="true"
      mat-stretch-tabs="false"
      [selectedIndex]="tabIndex"
    >
      <mat-tab *ngIf="view$ | async" label="Food">
        <div class="mt-4">
          <lean-food-viewer [food]="food"></lean-food-viewer>
        </div>
      </mat-tab>

      <mat-tab *ngIf="edit$ | async" label="Food">
        <div class="mt-4">
          <form class="flex flex-col h-full" [formGroup]="foodForm">
            <!-- description -->
            <mat-form-field class="w-full">
              <mat-label>Description</mat-label>
              <input matInput formControlName="description" />
            </mat-form-field>

            <!-- brand -->
            <mat-form-field class="w-4/5">
              <mat-label>Brand</mat-label>
              <input matInput formControlName="brand" />
            </mat-form-field>

            <!-- source -->
            <div class="flex items-baseline">
              <mat-form-field class="w-4/5 mr-2">
                <mat-label>Source</mat-label>
                <input matInput formControlName="source" />
              </mat-form-field>
              <button mat-icon-button (click)="openSourceUrl()"><mat-icon>link</mat-icon></button>
            </div>

            <!-- nutritional information -->
            <div class="flex h-16 w-[46rem] mb-5 border-b border-b-gray-400">
              <div class="flex items-center h-full grow text-xl">Nutritional Information</div>
              <div class="flex items-center h-full w-64">
                <mat-form-field subscriptSizing="dynamic" class="w-full">
                  <mat-label>Serving Size</mat-label>
                  <input matInput value="100 g" disabled />
                </mat-form-field>
              </div>
            </div>

            <!-- energy -->
            <mat-form-field class="w-32">
              <mat-label>Energy</mat-label>
              <input matInput formControlName="energy" />
              <span matTextSuffix>{{ food.energy.unit }}</span>
            </mat-form-field>

            <!-- protein -->
            <mat-form-field class="w-32">
              <mat-label>Protein</mat-label>
              <input matInput formControlName="protein" />
              <span matTextSuffix>{{ food.nutrients.protein.unit }}</span>
            </mat-form-field>

            <!-- carbs -->
            <div class="flex">
              <!-- carbs -->
              <mat-form-field class="w-32 mr-4">
                <mat-label>Carbs</mat-label>
                <input matInput formControlName="carbs" />
                <span matTextSuffix>{{ food.nutrients.carbs.unit }}</span>
              </mat-form-field>

              <!-- fibre -->
              <mat-form-field class="w-32 ml-4">
                <mat-label>Fibre</mat-label>
                <input matInput formControlName="fibre" />
                <span matTextSuffix>{{ food.nutrients.fibre.unit }}</span>
              </mat-form-field>

              <!-- sugar -->
              <mat-form-field class="w-32 ml-4">
                <mat-label>Sugar</mat-label>
                <input matInput formControlName="sugar" />
                <span matTextSuffix>{{ food.nutrients.sugar.unit }}</span>
              </mat-form-field>
            </div>

            <!-- fat -->
            <div class="flex">
              <!-- fat -->
              <mat-form-field class="w-32 mr-4">
                <mat-label>Fat</mat-label>
                <input matInput formControlName="fat" />
                <span matTextSuffix>{{ food.nutrients.fat.unit }}</span>
              </mat-form-field>

              <!-- sats -->
              <mat-form-field class="w-32 ml-4">
                <mat-label>Sats</mat-label>
                <input matInput formControlName="sats" />
                <span matTextSuffix>{{ food.nutrients.sats.unit }}</span>
              </mat-form-field>

              <!-- mono -->
              <mat-form-field class="w-32 ml-4">
                <mat-label>Mono</mat-label>
                <input matInput formControlName="mono" />
                <span matTextSuffix>{{ food.nutrients.mono.unit }}</span>
              </mat-form-field>

              <!-- poly -->
              <mat-form-field class="w-32 ml-4">
                <mat-label>Poly</mat-label>
                <input matInput formControlName="poly" />
                <span matTextSuffix>{{ food.nutrients.poly.unit }}</span>
              </mat-form-field>

              <!-- trans -->
              <mat-form-field class="w-32 ml-4">
                <mat-label>Trans</mat-label>
                <input matInput formControlName="trans" />
                <span matTextSuffix>{{ food.nutrients.trans.unit }}</span>
              </mat-form-field>
            </div>
          </form>
        </div>
      </mat-tab>

      <mat-tab *ngIf="edit$ | async" label="Serving Sizes">
        <div class="mt-4 w-64">
          <table mat-table [dataSource]="dataSource">
            <!-- name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="w-32">Name</th>
              <td mat-cell *matCellDef="let row">
                <mat-form-field class="w-full py-2" subscriptSizing="dynamic">
                  <mat-label>Name</mat-label>
                  <input matInput [formControl]="row.controls.name" />
                </mat-form-field>
              </td>
            </ng-container>

            <!-- weight -->
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef class="w-32">Weight (g)</th>
              <td mat-cell *matCellDef="let row">
                <mat-form-field class="w-32 py-2" subscriptSizing="dynamic">
                  <mat-label>Weight</mat-label>
                  <input matInput [formControl]="row.controls.weight" />
                  <span matTextSuffix>g</span>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- actions -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <button
                  mat-icon-button
                  matTooltip="Add"
                  matTooltipPosition="below"
                  (click)="addServingSize(); $event.stopPropagation()"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let row" class="!text-end">
                <button
                  mat-icon-button
                  matTooltip="Delete"
                  matTooltipPosition="below"
                  (click)="deleteServingSize(row); $event.stopPropagation()"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let servingSize; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-actions>
    <div class="flex w-full">
      <div class="flex w-1/2 justify-start">
        <button *ngIf="edit$ | async" mat-raised-button color="primary" (click)="view()">
          View
        </button>
      </div>
      <div class="flex w-1/2 justify-end">
        <button mat-raised-button (click)="cancel()">Cancel</button>
        <button *ngIf="view$ | async" mat-raised-button color="accent" (click)="edit()">
          Edit
        </button>
        <button *ngIf="edit$ | async" mat-raised-button color="accent" (click)="save()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
