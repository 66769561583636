import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgChanges, isDefined, isNil } from '@lean-life/common';
import { Food, JournalEntry, Meal, MealItem, MealTime, ServingSize } from '@lean-life/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, EMPTY, Observable, filter, map, skip, switchMap, take } from 'rxjs';
import { DialogData } from '../../dialog/dialog.model';
import { DialogService } from '../../dialog/dialog.service';
import { FoodPickerComponent } from '../../food/food-picker/food-picker.component';
import { FoodState } from '../../food/food.state';
import { ServingSizePickerComponent } from '../../serving-size-picker/serving-size-picker.component';
import { JournalService } from '../journal.service';
import { JournalState } from '../journal.state';

const none = -1;

@UntilDestroy()
@Component({
  selector: 'lean-meal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FoodPickerComponent,
    ServingSizePickerComponent,
  ],
  templateUrl: './meal.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MealComponent implements OnChanges {
  private store = inject(Store);
  private journalService = inject(JournalService);
  private dialogService = inject(DialogService);

  private entry!: JournalEntry;
  private clone: MealItem | null = null;
  private mode: 'add' | 'edit' = 'edit';

  @Select(JournalState.current) private currentEntry$!: Observable<JournalEntry>;
  @Select(FoodState.allFoods) private food$!: Observable<Food[]>;

  @Input() mealtime: MealTime = MealTime.Anytime;

  meal: Meal = new Meal(this.mealtime);
  dataSource = new MatTableDataSource<MealItem>();

  displayedColumns = [
    'description',
    'portion',
    'servingSize',
    'energy',
    'protein',
    'carbs',
    'fat',
    'actions',
  ];

  foodPicker = new FormControl();
  portionControl = new FormControl();
  servingControl = new FormControl();

  selectedIndex$ = new BehaviorSubject(none);
  isEditing$ = this.selectedIndex$.pipe(map((i) => i > none));

  private get selectedIndex() {
    return this.selectedIndex$.getValue();
  }

  private get selectedItem() {
    return this.meal.items[this.selectedIndex];
  }

  constructor() {
    this.subscribeToFoodPickerChanges();
    this.subscribeToPortionChanges();
  }

  ngOnChanges(changes: NgChanges<MealComponent>): void {
    if (changes.mealtime.firstChange) {
      this.subscribeToJournalEntry();
    }
  }

  onAddClick() {
    this.mode = 'add';
    const ordinal = this.meal.items.length;
    this.meal.addItem(new MealItem(ordinal, new Food()));
    this.dataSource.data = [...this.meal.items];
    this.selectedIndex$.next(ordinal);
  }

  onEditClick(index: number) {
    this.mode = 'edit';
    const item = this.meal.items[index];
    this.clone = item.clone();

    this.selectedIndex$.next(index);
    this.foodPicker.setValue(this.selectedItem.food);
    this.portionControl.setValue(this.selectedItem.portion);
    this.servingControl.setValue(this.selectedItem.servingSize);
  }

  onDeleteClick(index: number) {
    const item = this.dataSource.data[index];
    const confirmDelete = this.dialogService.show(
      new DialogData(
        `Confirm: Delete Meal Item`,
        `Are you sure you want to remove\r\n'${item.food.description}'?`,
        { text: 'Delete' },
        { text: 'Cancel', color: 'primary' }
      )
    );

    confirmDelete
      .pipe(
        switchMap((confirmed) => {
          if (!confirmed) return EMPTY;
          this.meal.removeIndex(index);
          this.entry.calculate();
          return this.saveJournalEntry();
        })
      )
      .subscribe(() => this.reset());
  }

  onServingSizeSelected(servingSize: ServingSize) {
    this.selectedItem.servingSize = servingSize;
    this.calculate();
  }

  onCancelClick() {
    if (this.mode === 'add') {
      this.meal.removeItem(this.selectedItem);
    } else if (this.clone) {
      this.selectedItem.update(this.clone);
      this.clone = null;
    }
    this.dataSource.data = [...this.meal.items];
    this.calculate();
    this.reset();
  }

  onSaveClick() {
    this.saveJournalEntry().subscribe(() => this.reset());
  }

  onRowClick() {
    // display more detail?
  }

  private saveJournalEntry() {
    return this.store.select(JournalState.current).pipe(
      filter(isDefined),
      take(1),
      switchMap((entry) => {
        return this.journalService.saveJournalEntry(entry);
      }),
      untilDestroyed(this)
    );
  }

  private subscribeToJournalEntry() {
    this.currentEntry$
      .pipe(
        filter(isDefined),
        map((entry) => {
          let meal = entry.meals.find((meal) => meal.mealTime === this.mealtime);
          if (isNil(meal)) {
            meal = new Meal(this.mealtime);
            entry.meals.splice(meal.ordinal, 1, meal);
          }
          return [entry, meal] as [JournalEntry, Meal];
        }),
        untilDestroyed(this)
      )
      .subscribe(([entry, meal]) => {
        this.entry = entry;
        this.meal = meal;
        this.dataSource.data = [...meal.items];
      });
  }

  private subscribeToFoodPickerChanges() {
    this.foodPicker.valueChanges.pipe(skip(1), untilDestroyed(this)).subscribe((value) => {
      this.selectedItem.food = value;
      this.dataSource.data = [...this.meal.items];
      this.portionControl.reset();
      this.servingControl.reset();
    });
  }

  private subscribeToPortionChanges() {
    this.portionControl.valueChanges.pipe(skip(1), untilDestroyed(this)).subscribe((value) => {
      this.selectedItem.portion = value;
      this.calculate();
    });
  }

  private calculate() {
    this.meal.calculate();
    this.entry.calculate();
  }

  private reset() {
    this.selectedIndex$.next(none);
    this.foodPicker.reset(null, { onlySelf: true, emitEvent: false });
    this.portionControl.reset(null, { onlySelf: true, emitEvent: false });
    this.servingControl.reset(null, { onlySelf: true, emitEvent: false });
  }
}
