import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { isDefined } from '@lean-life/common';
import { IsoDate, JournalEntry, UUID, toIsoDate, toLongDate } from '@lean-life/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, map, switchMap, take } from 'rxjs';
import { AppState } from '../../app-state/app.state';
import { CaloryBreakdownComponent } from '../calory-breakdown/calory-breakdown.component';
import { SetCurrentDate } from '../journal.actions';
import { JournalService } from '../journal.service';
import { JournalState } from '../journal.state';
import { NutritionGoalsComponent } from '../nutrition-goals/nutrition-goals.component';
import { NutritionLogComponent } from '../nutrition-log/nutrition-log.component';
import { TrainingSessionsComponent } from '../training-sessions/training-sessions.component';
import { WaterTrackerComponent } from '../water-tracker/water-tracker.component';

enum TargetDay {
  Previous = -1,
  Today = 0,
  Next = 1,
}

@UntilDestroy()
@Component({
  selector: 'lean-journal',
  standalone: true,
  imports: [
    CommonModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatToolbarModule,
    CaloryBreakdownComponent,
    NutritionGoalsComponent,
    NutritionLogComponent,
    TrainingSessionsComponent,
    WaterTrackerComponent,
  ],
  templateUrl: './journal.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
})
export class JournalComponent {
  private journalService = inject(JournalService);
  private store = inject(Store);

  @Select(JournalState.current) currentEntry$!: Observable<JournalEntry>;
  @Select(AppState.userId) userId$!: Observable<UUID>;

  public longDate$: Observable<string>;
  public isToday$: Observable<boolean>;

  constructor() {
    this.longDate$ = this.currentEntry$.pipe(
      map((entry) => {
        const date = entry ? new Date(entry.date) : new Date();
        return toLongDate(date);
      })
    );

    this.isToday$ = this.currentEntry$.pipe(
      map((entry) => {
        const isToday = isDefined(entry) ? entry.date === toIsoDate(new Date()) : true;
        return isToday;
      })
    );
  }

  previousDay() {
    this.moveToDay(TargetDay.Previous);
  }

  nextDay() {
    this.moveToDay(TargetDay.Next);
  }

  today() {
    this.moveToDay(TargetDay.Today);
  }

  private moveToDay(target: TargetDay) {
    this.currentEntry$
      .pipe(
        filter((entry) => isDefined(entry)),
        take(1),
        map((current) => this.getTargetDate(current.date, target)),
        switchMap((date) => this.goToDate(date)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getTargetDate(isoDate: IsoDate, target: TargetDay) {
    if (target === TargetDay.Today) return new Date();
    const date = new Date(isoDate);
    date.setDate(date.getDate() + target);
    return date;
  }

  goToDate(date: Date) {
    return this.userId$.pipe(
      switchMap((userId) => {
        return this.journalService.getOrCreateJournalEntry(userId, date);
      }),
      switchMap((entry) => this.store.dispatch(new SetCurrentDate(entry.date))),
      take(1)
    );
  }
}
