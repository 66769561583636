import { isNil } from '@lean-life/common';
import {
  Energy,
  Mass,
  Quantity,
  QuantityDto,
  Volume,
  calories,
  grams,
  volume,
} from '../quantities';
import { IsoDate, UUID, UserId_IsoDate, getId, toIsoDate } from '../utilities';
import { Food } from './food';
import { Meal, MealDto } from './meal';
import { MealItem } from './meal-item';
import { Nutrients, NutrientsDto } from './nutrients';
import { TrainingSession, TrainingSessionDto } from './training-session';

// @CosmosPartitionKey('userId')
export class JournalEntryDto {
  id!: UserId_IsoDate;
  userId!: UUID;
  date!: string;
  meals?: MealDto[];
  energy!: QuantityDto<Energy>;
  nutrients!: NutrientsDto;
  water!: QuantityDto<Volume>;
  training?: TrainingSessionDto[];
  // targets:
}

export class JournalEntry {
  readonly id!: UserId_IsoDate;
  readonly userId!: UUID;
  readonly date!: IsoDate;

  meals: Meal[] = [];
  energy = calories();
  nutrients = new Nutrients();

  water: Quantity<Volume> = volume(0, 'ml');
  training: TrainingSession[] = [];

  constructor(userId: UUID, date: Date | IsoDate) {
    this.id = getId(userId, date);
    this.userId = userId;
    this.date = toIsoDate(date);
  }

  static fromDto(dto: JournalEntryDto, foods: Food[]) {
    const entry = new JournalEntry(dto.userId, dto.date);
    entry.water = Quantity.fromDto(dto.water);

    entry.meals =
      dto.meals?.map((mealDto) => {
        const items = mealDto.items.map((itemDto) => {
          const food = foods.find((f) => f.id === itemDto.foodId);
          if (isNil(food)) {
            throw new Error(`Food not found: ${itemDto.foodId}`);
          }
          return MealItem.fromDto(itemDto, food);
        });
        return Meal.fromDto(mealDto, items);
      }) ?? [];

    entry.calculate();
    return entry;
  }

  toDto(): JournalEntryDto {
    return {
      id: this.id,
      userId: this.userId,
      date: this.date,
      meals: this.meals.map((meal) => meal.toDto()),
      energy: this.energy.toDto(),
      nutrients: this.nutrients.toDto(),
      water: this.water.toDto(),
      // training
    };
  }

  calculate() {
    const totalEnergy = this.meals.reduce((prev, curr) => {
      return (prev += curr.energy.value);
    }, 0);
    this.energy = calories(totalEnergy);

    const totals = this.meals.reduce((acc, curr) => {
      Object.entries(acc)
        .filter(([, value]) => value instanceof Quantity)
        .forEach(([key, qty]: [string, Quantity<Mass>]) => {
          const current = curr.nutrients[key as keyof NutrientsDto];
          acc[key as keyof NutrientsDto] = grams(qty.value + current.value);
        });
      return acc;
    }, new Nutrients());

    this.nutrients = totals;
  }
}
