import { Injectable, inject } from '@angular/core';
import { Food, UUID } from '@lean-life/models';
import { Action, NgxsOnInit, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { RemoveFood, SetFood, SetFoods } from './food.actions';
import { FoodService } from './food.service';

interface FoodStateModel {
  foods: Food[];
}

@State<FoodStateModel>({
  name: 'foodState',
})
@Injectable({ providedIn: 'root' })
export class FoodState implements NgxsOnInit {
  private service = inject(FoodService);

  ngxsOnInit(): void {
    this.service.getFoods().subscribe();
  }

  @Selector() static allFoods(state: FoodStateModel) {
    return state.foods;
  }

  static foodById(id: UUID): (state: FoodStateModel) => Food | undefined {
    return createSelector([FoodState], (state: FoodStateModel) => {
      return state.foods.find((food) => food.id === id);
    });
  }

  @Action(SetFood) setFood(ctx: StateContext<FoodStateModel>, { food }: SetFood) {
    const index = ctx.getState().foods.findIndex((f) => f.id === food.id);
    const operator = index < 0 ? insertItem<Food>(food) : updateItem<Food>(index, food);
    ctx.setState(patch({ foods: operator }));
  }

  @Action(SetFoods) setFoods(ctx: StateContext<FoodStateModel>, { foods }: SetFoods) {
    ctx.patchState({ foods: foods });
  }

  @Action(RemoveFood) removeFood(ctx: StateContext<FoodStateModel>, { id }: RemoveFood) {
    ctx.setState(patch({ foods: removeItem((food) => food.id === id) }));
  }
}
