import { Mass, MeasurementDto, Quantity, grams } from '../quantities';

export type ServingSizeDto = MeasurementDto<Mass>;

export class ServingSize {
  private _display?: string;

  get display(): string {
    return this._display ?? `${this.name} (${this.quantity.toString()})`;
  }

  set display(value: string) {
    this._display = value;
  }

  // prettier-ignore
  constructor(
    public readonly name: string,
    public readonly quantity: Quantity<Mass>,
    display?: string
  ) {
    this._display = display
  }

  static fromDto(dto: ServingSizeDto): ServingSize {
    return new ServingSize(dto.name, Quantity.fromDto(dto.quantity));
  }

  toDto(): ServingSizeDto {
    return { name: this.name, quantity: this.quantity.toDto() };
  }
}

export const hundredGrams = new ServingSize('100 g', grams(100), '100 g');
export const oneGram = new ServingSize('g', grams(1), 'g');
export const standardServingSizes = [hundredGrams, oneGram];
