import { Mass, Quantity, QuantityDto, mass } from '../quantities';

export const coffeeNutrients = {
  energy: { type: 'Energy', value: 1, unit: 'kcal' },

  protein: { type: 'Mass', value: 0.12, unit: 'g' },

  carbs: { type: 'Mass', value: 0, unit: 'g' },
  fibre: { type: 'Mass', value: 0, unit: 'g' },
  sugar: { type: 'Mass', value: 0, unit: 'g' },

  fat: { type: 'Mass', value: 0.02, unit: 'g' },
  sats: { type: 'Mass', value: 0, unit: 'g' },
  mono: { type: 'Mass', value: 0, unit: 'g' },
  poly: { type: 'Mass', value: 0, unit: 'g' },
  trans: { type: 'Mass', value: 0, unit: 'g' },
};

export const baconNutrients = {
  energy: { type: 'Energy', value: 501, unit: 'kcal' },

  protein: { type: 'Mass', value: 40.9, unit: 'g' },

  carbs: { type: 'Mass', value: 0, unit: 'g' },
  fibre: { type: 'Mass', value: 0, unit: 'g' },
  sugar: { type: 'Mass', value: 0, unit: 'g' },

  fat: { type: 'Mass', value: 36.5, unit: 'g' },
  sats: { type: 'Mass', value: 0, unit: 'g' },
  mono: { type: 'Mass', value: 0, unit: 'g' },
  poly: { type: 'Mass', value: 0, unit: 'g' },
  trans: { type: 'Mass', value: 0, unit: 'g' },
};

export const eggNutrients = {
  energy: { type: 'Energy', value: 143, unit: 'kcal' },

  protein: { type: 'Mass', value: 12.4, unit: 'g' },

  carbs: { type: 'Mass', value: 0, unit: 'g' },
  fibre: { type: 'Mass', value: 0, unit: 'g' },
  sugar: { type: 'Mass', value: 0, unit: 'g' },

  fat: { type: 'Mass', value: 9.96, unit: 'g' },
  sats: { type: 'Mass', value: 0, unit: 'g' },
  mono: { type: 'Mass', value: 0, unit: 'g' },
  poly: { type: 'Mass', value: 0, unit: 'g' },
  trans: { type: 'Mass', value: 0, unit: 'g' },
};

export const wholewheatNutrients = {
  energy: { type: 'Energy', value: 254, unit: 'kcal' },

  protein: { type: 'Mass', value: 12.3, unit: 'g' },

  carbs: { type: 'Mass', value: 43.1, unit: 'g' },
  fibre: { type: 'Mass', value: 0, unit: 'g' },
  sugar: { type: 'Mass', value: 0, unit: 'g' },

  fat: { type: 'Mass', value: 3.55, unit: 'g' },
  sats: { type: 'Mass', value: 0, unit: 'g' },
  mono: { type: 'Mass', value: 0, unit: 'g' },
  poly: { type: 'Mass', value: 0, unit: 'g' },
  trans: { type: 'Mass', value: 0, unit: 'g' },
};

export interface NutrientsDto {
  protein?: QuantityDto<Mass>;

  carbs?: QuantityDto<Mass>;
  fibre?: QuantityDto<Mass>;
  sugar?: QuantityDto<Mass>;

  fat?: QuantityDto<Mass>;
  sats?: QuantityDto<Mass>;
  mono?: QuantityDto<Mass>;
  poly?: QuantityDto<Mass>;
  trans?: QuantityDto<Mass>;
}

export class Nutrients {
  protein = mass(0, 'g');

  carbs = mass(0, 'g');
  fibre = mass(0, 'g');
  sugar = mass(0, 'g');

  fat = mass(0, 'g');
  sats = mass(0, 'g');
  mono = mass(0, 'g');
  poly = mass(0, 'g');
  trans = mass(0, 'g');

  static fromDto(dto: NutrientsDto): Nutrients {
    const model = new Nutrients();
    Object.entries(dto).forEach(([key, qty]) => {
      model[key as keyof NutrientsDto] = Quantity.fromDto(qty);
    });
    return model;
  }

  toDto(): NutrientsDto {
    const dto: NutrientsDto = {};
    Object.entries(this)
      .filter(([, value]) => value instanceof Quantity)
      .forEach(([key, value]: [string, Quantity<Mass>]) => {
        dto[key as keyof NutrientsDto] = value.toDto();
      });
    return dto;
  }
}
