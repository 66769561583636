import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { Food, FoodDto, caloriesDto, gramsDto } from '@lean-life/models';
import { BehaviorSubject, map } from 'rxjs';
import { FoodViewerComponent } from '../food-viewer/food-viewer.component';

export enum FoodEditorMode {
  View = 'view',
  Edit = 'edit',
}

export interface FoodEditorData {
  mode: FoodEditorMode;
  food: Food;
}

type FoodForm = {
  description: FormControl<string>;
  brand: FormControl<string>;
  source: FormControl<string>;
  energy: FormControl<number>;
  protein: FormControl<number>;
  carbs: FormControl<number>;
  fibre: FormControl<number>;
  sugar: FormControl<number>;
  fat: FormControl<number>;
  sats: FormControl<number>;
  mono: FormControl<number>;
  poly: FormControl<number>;
  trans: FormControl<number>;
};

type ServingSizeForm = {
  name: FormControl<string>;
  weight: FormControl<number>;
};

@Component({
  selector: 'lean-food-editor',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    FoodViewerComponent,
  ],
  templateUrl: './food-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodEditorComponent {
  private dialogRef = inject(MatDialogRef<FoodEditorComponent, FoodDto>);
  private formBuilder = inject(FormBuilder);
  private data: FoodEditorData = inject(MAT_DIALOG_DATA);

  private mode$ = new BehaviorSubject(this.data.mode);
  view$ = this.mode$.pipe(map((mode) => mode === FoodEditorMode.View));
  edit$ = this.mode$.pipe(map((mode) => mode === FoodEditorMode.Edit));

  title$ = this.mode$.pipe(
    map((mode) => {
      return mode === FoodEditorMode.View
        ? 'View Food'
        : this.food.isNew
        ? 'Add Food'
        : 'Edit Food';
    })
  );

  tabIndex = 0;
  food = this.data.food;

  foodForm!: FormGroup<FoodForm>;
  servingSize = 'option1';

  servingSizeFormArray = new FormArray<FormGroup<ServingSizeForm>>([]);
  dataSource = new MatTableDataSource<FormGroup<ServingSizeForm>>();
  displayedColumns = ['name', 'weight', 'actions'];

  constructor() {
    this.createFoodForm();
    this.createServingSizeFormArray();
  }

  openSourceUrl() {
    window.open(this.food.source, '_blank');
  }

  view() {
    this.mode$.next(FoodEditorMode.View);
  }

  edit() {
    this.mode$.next(FoodEditorMode.Edit);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  addServingSize() {
    this.servingSizeFormArray.push(this.createServingSizeForm('', 0));
    this.dataSource.data = this.servingSizeFormArray.controls;
  }

  deleteServingSize(formGroup: FormGroup<ServingSizeForm>) {
    const index = this.servingSizeFormArray.controls.indexOf(formGroup);
    if (index < 0) return;
    this.servingSizeFormArray.removeAt(index);
    this.dataSource.data = this.servingSizeFormArray.controls;
  }

  save() {
    const form = { ...this.foodForm.value };
    const dto: FoodDto = {
      id: this.food.id,
      userId: this.food.userId,
      description: this.foodForm.controls.description.value,
      brand: form.brand,
      source: form.source,
      energy: caloriesDto(form.energy),
      nutrients: {
        protein: gramsDto(form.protein),
        carbs: gramsDto(form.carbs),
        fibre: gramsDto(form.fibre),
        sugar: gramsDto(form.sugar),
        fat: gramsDto(form.fat),
        sats: gramsDto(form.sats),
        mono: gramsDto(form.mono),
        poly: gramsDto(form.poly),
        trans: gramsDto(form.trans),
      },
      servingSizes: this.servingSizeFormArray.value
        .filter((ss) => ss.name && ss.name.trim()?.length > 0)
        .map((ss) => {
          return { name: ss.name as string, quantity: gramsDto(ss.weight) };
        }),
    };
    this.dialogRef.close(dto);
  }

  private createFoodForm() {
    const food = this.data.food;
    const { nutrients } = food;

    this.foodForm = this.formBuilder.group({
      description: new FormControl(food.description ?? '', {
        nonNullable: true,
        validators: Validators.required,
      }),
      brand: new FormControl(food.brand ?? '', { nonNullable: true }),
      source: new FormControl(food.source ?? '', { nonNullable: true }),
      energy: new FormControl(food.energy.value, { nonNullable: true }),
      protein: new FormControl(nutrients.protein.value, { nonNullable: true }),
      carbs: new FormControl(nutrients.carbs.value, { nonNullable: true }),
      fibre: new FormControl(nutrients.fibre.value, { nonNullable: true }),
      sugar: new FormControl(nutrients.sugar.value, { nonNullable: true }),
      fat: new FormControl(nutrients.fat.value, { nonNullable: true }),
      sats: new FormControl(nutrients.sats.value, { nonNullable: true }),
      mono: new FormControl(nutrients.mono.value, { nonNullable: true }),
      poly: new FormControl(nutrients.poly.value, { nonNullable: true }),
      trans: new FormControl(nutrients.trans.value, { nonNullable: true }),
    });
  }

  private createServingSizeFormArray() {
    this.food.servingSizes.forEach((ss) => {
      const form = this.createServingSizeForm(ss.name, ss.quantity.value);
      this.servingSizeFormArray.push(form);
    });

    this.servingSizeFormArray.push(this.createServingSizeForm('', 0));
    this.dataSource.data = this.servingSizeFormArray.controls;
  }

  private createServingSizeForm(name: string, value: number) {
    return this.formBuilder.group<ServingSizeForm>({
      name: new FormControl(name, {
        nonNullable: true,
        validators: Validators.required,
      }),
      weight: new FormControl(value, {
        nonNullable: true,
      }),
    });
  }
}
