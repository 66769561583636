import { QuantityDefinition } from './quantity-types';

export type UnitlessType = {
  name: 'Unitless';
  baseUnit: '';
  unitKeys: '%';
};

export type Unitless = UnitlessType['name'];

export const unitlessDefinition: QuantityDefinition<Unitless> = {
  name: 'Unitless',
  baseUnit: {
    symbol: '',
    singularName: '',
    pluralName: '',
  },
  units: {
    '%': {
      symbol: '%',
      singularName: 'percent',
      pluralName: 'percent',
      fromUnitToBaseFunc: (x: number): number => x,
      fromBaseToUnitFunc: (x: number): number => x,
    },
  },
};
