import { AbstractControl, FormControlStatus } from '@angular/forms';
import { BehaviorSubject, Observable, defer } from 'rxjs';
import { map, share, startWith } from 'rxjs/operators';

export type RxControlValueState = 'PRISTINE' | 'DIRTY';
export type RxControlTouchState = 'UNTOUCHED' | 'TOUCHED';
export type RxControlState = [RxControlValueState, RxControlTouchState];

export function stateChanges(control: AbstractControl): Observable<RxControlState> {
  const rxControl = control as AbstractControl & { state$: Observable<RxControlState> };
  if (rxControl.state$) return rxControl.state$;

  const markAsDirty = control.markAsDirty.bind(control);
  const markAsPristine = control.markAsPristine.bind(control);

  const markAsTouched = control.markAsTouched.bind(control);
  const markAsUntouched = control.markAsUntouched.bind(control);
  const markAllAsTouched = control.markAllAsTouched.bind(control);

  const reset = control.reset.bind(control);

  const valueState = (control: AbstractControl) => (control.pristine ? 'PRISTINE' : 'DIRTY');
  const touchState = (control: AbstractControl) => (control.untouched ? 'UNTOUCHED' : 'TOUCHED');

  const stateOf = (control: AbstractControl): RxControlState => [
    valueState(control),
    touchState(control),
  ];

  const state$ = new BehaviorSubject<RxControlState>(stateOf(control));
  rxControl.state$ = state$.asObservable().pipe(share());

  control.markAsDirty = (opts?: { onlySelf?: boolean | undefined } | undefined): void => {
    markAsDirty(opts);
    state$.next(stateOf(control));
  };

  control.markAsPristine = (opts?: { onlySelf?: boolean | undefined } | undefined): void => {
    markAsPristine(opts);
    state$.next(stateOf(control));
  };

  control.markAsTouched = (opts?: { onlySelf?: boolean | undefined } | undefined): void => {
    markAsTouched(opts);
    state$.next(stateOf(control));
  };

  control.markAsUntouched = (opts?: { onlySelf?: boolean | undefined } | undefined): void => {
    markAsUntouched(opts);
    state$.next(stateOf(control));
  };

  control.markAllAsTouched = (): void => {
    markAllAsTouched();
    state$.next(stateOf(control));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control.reset = (value?: any, options?: object | undefined): void => {
    reset(value, options);
    state$.next(stateOf(control));
  };

  return rxControl.state$;
}

export function statusOf(control: AbstractControl): Observable<FormControlStatus> {
  return defer(() => control.statusChanges.pipe(startWith(control.status)));
}

export function valuesOf<T>(control: AbstractControl): Observable<T | null> {
  return defer(() => control.valueChanges.pipe(startWith(control.value)));
}

export function isDisabled(control: AbstractControl) {
  return statusOf(control).pipe(map((status) => status === 'DISABLED'));
}

export function isInvalid(control: AbstractControl) {
  return statusOf(control).pipe(map((status) => status === 'INVALID'));
}

export function isPending(control: AbstractControl) {
  return statusOf(control).pipe(map((status) => status === 'PENDING'));
}

export function isValid(control: AbstractControl) {
  return statusOf(control).pipe(map((status) => status === 'VALID'));
}

export function isPristine(control: AbstractControl) {
  return stateChanges(control).pipe(map((state) => state[0] === 'PRISTINE'));
}

export function isDirty(control: AbstractControl) {
  return stateChanges(control).pipe(map((state) => state[0] === 'DIRTY'));
}

export function isTouched(control: AbstractControl) {
  return stateChanges(control).pipe(map((state) => state[1] === 'TOUCHED'));
}

export function isUntouched(control: AbstractControl) {
  return stateChanges(control).pipe(map((state) => state[1] === 'UNTOUCHED'));
}
