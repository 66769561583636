import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DialogData } from './dialog.model';

@Component({
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule, MatIconModule],
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  private dialogRef = inject(MatDialogRef<DialogComponent, boolean>);
  data: DialogData = inject(MAT_DIALOG_DATA);

  cancelClick(): void {
    this.dialogRef.close(false);
  }

  okClick(): void {
    this.dialogRef.close(true);
  }
}
