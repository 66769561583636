import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './app-state/app.state';
import { appRoutes } from './app.routes';
import { AuthModule } from './auth/auth.module';
import { FoodState } from './food/food.state';
import { JournalState } from './journal/journal.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      AuthModule,
      MatDialogModule,
      NgxsModule.forRoot([AppState, FoodState, JournalState], {
        selectorOptions: {
          // These Selector Settings are recommended in preparation for NGXS v4
          // (See https://www.ngxs.io/concepts/select#joining-selectors for their effects)
          suppressErrors: false,
          injectContainerState: false,
        },
      })
    ),
  ],
};
