import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { isNil } from '@lean-life/common';
import { Food, FoodDto } from '@lean-life/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { EMPTY, switchMap, take } from 'rxjs';
import { AppState } from '../../app-state/app.state';
import { DialogData } from '../../dialog/dialog.model';
import { DialogService } from '../../dialog/dialog.service';
import {
  FoodEditorComponent,
  FoodEditorData,
  FoodEditorMode,
} from '../food-editor/food-editor.component';
import { FoodTableComponent } from '../food-table/food-table.component';
import { FoodService } from '../food.service';

@UntilDestroy()
@Component({
  selector: 'lean-food-list',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    FoodTableComponent,
  ],
  templateUrl: './food-list.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodListComponent {
  private store = inject(Store);
  private dialog = inject(MatDialog);
  private foodService = inject(FoodService);
  private dialogService = inject(DialogService);

  filterControl = new FormControl('');

  refresh() {
    this.foodService.getFoods().pipe(take(1), untilDestroyed(this)).subscribe();
  }

  addFood() {
    this.openFoodEditor(FoodEditorMode.Edit, new Food());
  }

  clearFilter() {
    this.filterControl.setValue('');
  }

  eatFood(food: Food) {
    this.dialogService.show(
      new DialogData('I ate this', `I ate this\r\n'${food.description}'\r\n(and a cannibal...)`)
    );
  }

  editFood(food: Food) {
    this.openFoodEditor(FoodEditorMode.Edit, food);
  }

  deleteFood(food: Food) {
    const confirmDelete = this.dialogService.show(
      new DialogData(
        `Confirm: Delete Food`,
        `Are you sure you want to delete\r\n'${food.description}'?`,
        { text: 'Delete' },
        { text: 'Cancel', color: 'primary' }
      )
    );

    confirmDelete
      .pipe(
        switchMap((confirmed) => {
          if (!confirmed) return EMPTY;
          return this.foodService.deleteFood(food.id);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  viewFood(food: Food) {
    this.openFoodEditor(FoodEditorMode.View, food);
  }

  private openFoodEditor(mode: FoodEditorMode, food: Food) {
    const sidenavOpen = this.store.selectSnapshot(AppState.sidenavOpen);
    const positionLeft = sidenavOpen ? 'calc(50vw - 30vw + 128px)' : 'calc(50vw - 30vw)';

    const dialogRef = this.dialog.open<FoodEditorComponent, FoodEditorData, FoodDto>(
      FoodEditorComponent,
      {
        height: '48rem',
        width: '72rem',
        position: { left: positionLeft },
        disableClose: true,
        data: { mode, food },
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((dto) => {
          if (isNil(dto)) return EMPTY;
          return this.foodService.saveFood(dto);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
