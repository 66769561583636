<mat-toolbar>
  <div class="flex grow justify-start">Food</div>
</mat-toolbar>

<div class="h-full p-4">
  <div class="flex h-[52px] mt-2 mb-6">
    <mat-form-field class="w-1/2" subscriptSizing="dynamic">
      <mat-label>Filter</mat-label>
      <input matInput [formControl]="filterControl" />
      <button mat-icon-button matSuffix (click)="clearFilter()">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <lean-food-table
    class="mt-4"
    [filter]="filterControl.valueChanges | async"
    (refreshClick)="refresh()"
    (addClick)="addFood()"
    (eatClick)="eatFood($event)"
    (editClick)="editFood($event)"
    (deleteClick)="deleteFood($event)"
    (rowClick)="viewFood($event)"
  ></lean-food-table>
</div>
