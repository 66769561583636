import { QuantityDefinition } from './quantity-types';

export type TimeType = {
  name: 'Time';
  baseUnit: 's';
  unitKeys: 'ms' | 'min' | 'h' | 'd';
};

export type Time = TimeType['name'];

export const timeDefinition: QuantityDefinition<Time> = {
  name: 'Time',
  baseUnit: {
    symbol: 's',
    singularName: 'second',
    pluralName: 'seconds',
  },
  units: {
    ms: {
      symbol: 'ms',
      singularName: 'millisecond',
      pluralName: 'milliseconds',
      fromUnitToBaseFunc: (x: number): number => x / 1e3,
      fromBaseToUnitFunc: (x: number): number => x * 1e3,
    },
    min: {
      symbol: 'min',
      singularName: 'minute',
      pluralName: 'minutes',
      fromUnitToBaseFunc: (x: number): number => x * 60,
      fromBaseToUnitFunc: (x: number): number => x / 60,
    },
    h: {
      symbol: 'h',
      singularName: 'hour',
      pluralName: 'hours',
      fromUnitToBaseFunc: (x: number): number => x * 3600,
      fromBaseToUnitFunc: (x: number): number => x / 3600,
    },
    d: {
      symbol: 'd',
      singularName: 'day',
      pluralName: 'days',
      fromUnitToBaseFunc: (x: number): number => x * 86400,
      fromBaseToUnitFunc: (x: number): number => x / 86400,
    },
  },
};
