export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isNil(value: unknown): value is undefined | null {
  return value === undefined || value === null;
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date && !isNaN(value as never);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}
