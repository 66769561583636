import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { isDefined } from '@lean-life/common';
import { JournalEntry, JournalEntryDto, Quantity, UUID, Volume } from '@lean-life/models';
import { Store } from '@ngxs/store';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { appSettings } from '../../../settings/settings';
import { AppState } from '../../app-state/app.state';
import { SetWater } from '../journal.actions';
import { JournalState } from '../journal.state';

@Injectable({ providedIn: 'root' })
export class WaterTrackerService {
  private http = inject(HttpClient);
  private store = inject(Store);
  private endpoint = appSettings.apiEndpoint;

  increment(qty: Quantity<Volume>) {
    return this.patch('incr', qty);
  }

  decrement(qty: Quantity<Volume>) {
    return this.patch('decr', qty);
  }

  private patch(action: 'incr' | 'decr', qty: Quantity<Volume>) {
    return combineLatest([
      this.store.selectOnce(AppState.userId),
      this.store.selectOnce(JournalState.current),
    ]).pipe(
      filter((arg): arg is [UUID, JournalEntry] => isDefined(arg[0]) && isDefined(arg[1])),
      map((arg) => adjustWater(arg, action, qty)),
      switchMap(([userId, date, value]) => {
        return this.http.patch<JournalEntryDto>(`${this.endpoint}/api/journal/${userId}/${date}`, {
          water: { quantity: 'Volume', unit: 'ml', value },
        });
      }),
      switchMap((dto) => this.store.dispatch(new SetWater(Quantity.fromDto(dto.water))))
    );
  }
}

function adjustWater(arg: [UUID, JournalEntry], action: string, qty: Quantity<Volume>) {
  const [userId, entry] = arg;
  const current = entry.water.convertTo('ml').value;
  const adjust = qty.convertTo('ml').value;
  const value = action === 'incr' ? current + adjust : Math.max(current - adjust, 0);
  return [userId, entry.date, value];
}
