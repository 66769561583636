import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Profile } from '@lean-life/models';

@Component({
  selector: 'lean-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
  @Input() profile!: Profile;
}
