import { isNil } from '@lean-life/common';
import { quantityDefs } from './quantity-defs';
import { QuantityName } from './quantity-names';
import { Unit, UnitOf } from './quantity-types';

export interface QuantityDto<T extends QuantityName, U extends T = T> {
  type: T;
  value: number;
  unit: UnitOf<U>;
}

export class Quantity<T extends QuantityName, U extends T = T> {
  constructor(
    public readonly type: T,
    public readonly value: number,
    public readonly unit: UnitOf<U>
  ) {}

  static fromDto<T extends QuantityName, U extends T = T>(dto: QuantityDto<T, U>): Quantity<T, U> {
    return new Quantity(dto.type, dto.value, dto.unit);
  }

  toDto(): QuantityDto<T, U> {
    return { type: this.type, value: this.value, unit: this.unit };
  }

  convertTo(unit: UnitOf<U>) {
    if (unit === this.unit) {
      return new Quantity(this.type, this.value, this.unit);
    }

    const definition = quantityDefs[this.type];
    if (isNil(definition)) throw new Error(this.definitionNotFound(this.type));

    const sourceIsBase = this.unit === definition.baseUnit.symbol;
    const targetIsBase = unit === definition.baseUnit.symbol;

    const source = sourceIsBase ? definition.baseUnit : definition.units[this.unit];
    if (isNil(source)) throw new Error(this.definitionNotFound(this.unit));

    const target = targetIsBase ? definition.baseUnit : definition.units[unit];
    if (isNil(target)) throw new Error(this.definitionNotFound(unit));

    const baseValue = sourceIsBase
      ? this.value
      : (source as Unit<U>).fromUnitToBaseFunc(this.value);

    const targetValue = targetIsBase
      ? baseValue
      : (target as Unit<U>).fromBaseToUnitFunc(baseValue);

    return new Quantity(this.type, targetValue, unit);
  }

  toString() {
    return `${this.value} ${this.unit}`;
  }

  private definitionNotFound(key: string) {
    return `Could not find definition for ${key}.`;
  }
}

export function quantityOf<T extends QuantityName, U extends T = T>(
  type: T,
  value: number,
  unit: UnitOf<U>
) {
  return new Quantity(type, value, unit);
}
