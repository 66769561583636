import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Food, FoodDto, UUID } from '@lean-life/models';
import { Select, Store } from '@ngxs/store';
import { Observable, map, switchMap } from 'rxjs';
import { appSettings } from '../../settings/settings';
import { AppState } from '../app-state/app.state';
import { RemoveFood, SetFood, SetFoods } from './food.actions';

@Injectable({ providedIn: 'root' })
export class FoodService {
  private http = inject(HttpClient);
  private store = inject(Store);
  private endpoint = appSettings.apiEndpoint;

  @Select(AppState.userId) private userId$!: Observable<UUID>;

  getFoodById(id: UUID): Observable<Food> {
    const url = `${this.endpoint}/api/food/${id}`;
    return this.http.get<FoodDto>(url).pipe(
      map((dto) => Food.fromDto(dto)),
      switchMap((food) => {
        return this.store.dispatch(new SetFood(food)).pipe(map(() => food));
      })
    );
  }

  getFoods(): Observable<Food[]> {
    const url = `${this.endpoint}/api/food`;
    return this.http.get<FoodDto[]>(url).pipe(
      map((dtos) => dtos ?? []),
      map((dtos) => dtos.map((dto) => Food.fromDto(dto))),
      switchMap((foods) => {
        return this.store.dispatch(new SetFoods(foods)).pipe(map(() => foods));
      })
    );
  }

  saveFood(dto: FoodDto) {
    const action = dto.id === '' ? this.createFood(dto) : this.updateFood(dto);
    return action.pipe(
      map(Food.fromDto),
      switchMap((food) => this.store.dispatch(new SetFood(food)).pipe(map(() => food)))
    );
  }

  deleteFood(id: UUID) {
    const url = `${this.endpoint}/api/food`;
    return this.userId$.pipe(
      switchMap((userId) => {
        return this.http.delete(`${url}/${userId}/${id}`);
      }),
      switchMap(() => this.store.dispatch(new RemoveFood(id)))
    );
  }

  private createFood(dto: FoodDto) {
    const url = `${this.endpoint}/api/food`;
    return this.userId$.pipe(
      switchMap((userId) => {
        dto.userId = userId;
        return this.http.post<FoodDto>(url, dto);
      })
    );
  }

  private updateFood(dto: FoodDto) {
    const url = `${this.endpoint}/api/food/${dto.userId}/${dto.id}`;
    return this.http.put<FoodDto>(url, dto);
  }
}
