<div class="shadow-md">
  <table
    mat-table
    matSort
    matSortActive="description"
    matSortDirection="asc"
    matSortDisableClear
    [dataSource]="dataSource"
    class="w-full"
  >
    <!-- description -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let food">{{ food.description }}</td>
    </ng-container>

    <!-- brand -->
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
      <td mat-cell *matCellDef="let food">{{ food.brand }}</td>
    </ng-container>

    <!-- energy -->
    <ng-container matColumnDef="energy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Energy</th>
      <td mat-cell *matCellDef="let food" class="!text-end">{{ food.energy.value | number }}</td>
    </ng-container>

    <!-- protein -->
    <ng-container matColumnDef="protein">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Protein</th>
      <td mat-cell *matCellDef="let food" class="!text-end">
        {{ food.nutrients.protein.value | number : '1.2-2' }}
      </td>
    </ng-container>

    <!-- carbs -->
    <ng-container matColumnDef="carbs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Carbs</th>
      <td mat-cell *matCellDef="let food" class="!text-end">
        {{ food.nutrients.carbs.value | number : '1.2-2' }}
      </td>
    </ng-container>

    <!-- fat -->
    <ng-container matColumnDef="fat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fat</th>
      <td mat-cell *matCellDef="let food" class="!text-end">
        {{ food.nutrients.fat.value | number : '1.2-2' }}
      </td>
    </ng-container>

    <!-- actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef="let food" class="!text-end">
        <button mat-icon-button matTooltip="Refresh" (click)="onRefreshClick()">
          <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Add" (click)="onAddClick()">
          <mat-icon>add</mat-icon>
        </button>
      </th>

      <td mat-cell *matCellDef="let food" class="!text-end">
        <button
          mat-icon-button
          matTooltip="I ate this"
          matTooltipPosition="below"
          (click)="onEatClick(food); $event.stopPropagation()"
        >
          <mat-icon>restaurant</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Edit"
          matTooltipPosition="below"
          (click)="onEditClick(food); $event.stopPropagation()"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Delete Food"
          matTooltipPosition="below"
          (click)="onDeleteClick(food); $event.stopPropagation()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let food; columns: displayedColumns"
      class="hover:cursor-pointer"
      (click)="onRowClick(food)"
    ></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[25, 50, 100, 250]"
    class="border-t border-gray-400"
  >
  </mat-paginator>
</div>
