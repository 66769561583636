<mat-toolbar color="primary">
  <button
    type="button"
    mat-icon-button
    [disabled]="(loggedIn$ | async) === false"
    (click)="toggleSidenav()"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <div class="text-xl">Lean</div>

  <div class="flex flex-grow justify-end items-center">
    <button mat-raised-button *ngIf="(loggedIn$ | async) === false" (click)="login()">Login</button>

    <ng-container *ngIf="profile$ | async; let profile">
      <button mat-button class="!h-12 pr-0" (click)="menuTrigger.openMenu()">
        <div
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="profileMenu"
          class="absolute top-10 right-0"
        ></div>
        <lean-profile [profile]="profile"></lean-profile>
      </button>

      <mat-menu #profileMenu="matMenu" class="w-48 mt-2">
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="side"
    class="w-64"
    fixedInViewport
    fixedTopGap="60"
    [opened]="sidenavOpen$ | async"
  >
    <div *ngIf="loggedIn$ | async" class="flex flex-col h-full">
      <mat-action-list class="flex fex-col grow">
        <mat-list-item (click)="navigate('journal')">
          <div class="flex items-center">
            <mat-icon class="mr-3">dashboard</mat-icon>
            <button class="">Journal</button>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigate('food')">
          <div class="flex items-center">
            <mat-icon class="mr-3">dinner_dining</mat-icon>
            <button class="">Food</button>
          </div>
        </mat-list-item>

        <mat-list-item (click)="navigate('locker-room')">
          <div class="flex items-center">
            <mat-icon class="mr-3">fitness_center</mat-icon>
            <button class="">Locker Room</button>
          </div>
        </mat-list-item>
      </mat-action-list>

      <mat-action-list *ngIf="loggedIn$ | async">
        <mat-list-item (click)="navigate('preferences')">
          <div class="flex items-center">
            <mat-icon class="mr-3">settings</mat-icon>
            <button class="">Preferences</button>
          </div>
        </mat-list-item>
      </mat-action-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-[calc(100vh-60px)]" *ngIf="(showRouterOutlet$ | async) === true">
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
