import { Energy, Quantity, QuantityDto, energy, mass } from '../quantities';
import { MealItem, MealItemDto } from './meal-item';
import { Nutrients, NutrientsDto } from './nutrients';

export enum MealTime {
  'Breakfast' = 'Breakfast',
  'MidMorning' = 'Mid-Morning',
  'Lunch' = 'Lunch',
  'MidAfternoon' = 'Mid-Afternoon',
  'Dinner' = 'Dinner',
  'LateNight' = 'Late Night',
  'Anytime' = 'Anytime',
}

const ordinalMap = new Map<string, number>([
  [MealTime.Breakfast, 0],
  [MealTime.MidMorning, 1],
  [MealTime.Lunch, 2],
  [MealTime.MidAfternoon, 3],
  [MealTime.Dinner, 4],
  [MealTime.LateNight, 5],
  [MealTime.Anytime, 9],
]);

export interface MealDto {
  ordinal: number;
  mealTime: MealTime;
  items: MealItemDto[];
  energy: QuantityDto<Energy>;
  nutrients: NutrientsDto;
}

export class Meal {
  private _items: MealItem[] = [];
  private _energy = energy(0, 'kcal');
  private _nutrients = new Nutrients();

  get ordinal(): number {
    return ordinalMap.get(this.mealTime) ?? 9;
  }

  get items(): ReadonlyArray<MealItem> {
    return [...this._items];
  }

  get energy(): Quantity<Energy> {
    return Quantity.fromDto(this._energy);
  }

  get nutrients(): Nutrients {
    return Nutrients.fromDto(this._nutrients);
  }

  constructor(public readonly mealTime = MealTime.Anytime) {}

  static fromDto(dto: MealDto, items: MealItem[]): Meal {
    const model = new Meal(dto.mealTime);
    model._items.push(...items);
    model.calculate();
    return model;
  }

  toDto(): MealDto {
    return {
      ordinal: this.ordinal,
      mealTime: this.mealTime,
      items: this.items.map((item) => item.toDto()),
      energy: this._energy.toDto(),
      nutrients: this._nutrients.toDto(),
    };
  }

  addItem(item: MealItem) {
    this._items.push(item);
    this.calculate();
  }

  removeItem(item: MealItem) {
    const index = this._items.findIndex((i) => i.ordinal === item.ordinal);
    return this.removeIndex(index);
  }

  removeIndex(index: number) {
    const result = this._items.splice(index, 1);
    const success = result.length === 1;
    if (success) this.calculate();
    return success;
  }

  calculate() {
    this._energy = this._items.reduce((prev, curr) => {
      return energy(prev.value + curr.energy.value, 'kcal');
    }, energy(0, 'kcal'));

    Object.entries(this._nutrients)
      .filter(([, value]) => value instanceof Quantity)
      .forEach(([key]) => {
        const indexer = key as unknown as keyof NutrientsDto;

        const result = this._items.reduce((prev, curr) => {
          const currValue = curr.nutrients[indexer].value;
          return mass(prev.value + currValue, 'g');
        }, mass(0, 'g'));

        this._nutrients[indexer] = result;
      });
  }
}
