import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { isDefined, isNil, isTouched } from '@lean-life/common';
import { Food } from '@lean-life/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable, Subject, combineLatestWith, distinctUntilChanged, filter, map } from 'rxjs';
import { FoodState } from '../food.state';

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

@UntilDestroy()
@Component({
  selector: 'lean-food-picker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FoodPickerComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FoodPickerComponent,
      multi: true,
    },
  ],
  templateUrl: './food-picker.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodPickerComponent implements OnInit, ControlValueAccessor, Validator {
  private initialValue!: Food;
  private hasInitialValue = false;

  private input$ = new Subject<string>();

  pickerControl = new FormControl<Food | null>(null, { validators: Validators.required });
  filteredFood$: Observable<Food[]>;

  @Select(FoodState.allFoods) private food$!: Observable<Food[]>;

  @Input() set food(food: Food) {
    this.pickerControl.setValue(food);
  }

  @ViewChild('auto') auto!: MatAutocomplete;

  constructor() {
    const sortedFood$ = this.food$.pipe(
      map((food) => food.sort((a, b) => a.description.localeCompare(b.description)))
    );

    this.filteredFood$ = this.input$.pipe(
      combineLatestWith(sortedFood$),
      map(([filter, food]) => {
        if (filter.trim() === '') return food;
        return food.filter((f) => f.description.toLowerCase().includes(filter.toLowerCase()));
      })
    );
  }

  ngOnInit(): void {
    // HACK: https://github.com/angular/components/issues/26761
    this.input$
      .pipe(
        filter(() => isDefined(this.pickerControl.value)),
        untilDestroyed(this)
      )
      .subscribe((input) => {
        if (input !== this.pickerControl.value?.description) {
          this.pickerControl.setValue(null, { emitEvent: true, emitModelToViewChange: false });
          this.auto.options.forEach((option) => option.deselect(false));
        }
      });

    isTouched(this.pickerControl)
      .pipe(
        distinctUntilChanged(),
        filter((isTouched) => isTouched),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.onTouched();
        },
      });
  }

  onChange = (_: unknown) => {};
  onTouched = () => {};
  onValidatorChange = () => {};

  writeValue(value: Food): void {
    if (!this.hasInitialValue) {
      this.initialValue = value;
      this.hasInitialValue = true;
      return value ? this.pickerControl.setValue(value) : void 0;
    }

    if (equals(value, this.initialValue) || value === null) {
      return this.pickerControl.reset(value ?? undefined, { emitEvent: false });
    }

    this.pickerControl.setValue(value);
  }

  registerOnChange(fn: never): void {
    this.onChange = fn;
    this.pickerControl.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
  }

  registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.pickerControl.disable() : this.pickerControl.enable();
  }

  validate(control: AbstractControl<Food>): ValidationErrors | null {
    return isNil(control.value) ? { required: true } : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  filter(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.input$.next(value);
  }

  displayWithFn(food: Food) {
    return food?.description ?? '';
  }

  onFoodSelected(food: Food) {
    this.pickerControl.setValue(food);
  }

  trackByFn(index: number, food: Food) {
    return food.id;
  }
}

function equals(a: Food, b: Food) {
  if (a === null && b === null) return true;
  return a?.id === b?.id && a?.description === b?.description;
}
