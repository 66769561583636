<div class="flex flex-col h-full">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <mat-divider></mat-divider>

  <div mat-dialog-content class="flex-auto p-4 text-sm">
    <div class="whitespace-pre">{{ data.message }}</div>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-actions>
    <div class="flex w-full justify-end">
      <button
        *ngIf="data.cancelButton"
        mat-raised-button
        [color]="data.cancelButton.color"
        class="mr-2"
        (click)="cancelClick()"
      >
        {{ data.cancelButton.text }}
      </button>
      <button mat-raised-button [color]="data.okButton.color" (click)="okClick()">
        {{ data.okButton.text }}
      </button>
    </div>
  </div>
</div>
