import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  Energy,
  Food,
  Mass,
  Measurement,
  Nutrients,
  Quantity,
  calories,
  grams,
} from '@lean-life/models';
import { ServingSizePickerComponent } from '../../serving-size-picker/serving-size-picker.component';

type ServingSizeOption = {
  name: string;
  quantity: Quantity<'Mass', 'Mass'>;
  display: string;
};

@Component({
  selector: 'lean-food-viewer',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    ServingSizePickerComponent,
  ],
  templateUrl: './food-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodViewerComponent {
  private _food: Food = new Food();

  get food(): Food {
    return this._food;
  }

  @Input() set food(value: Food) {
    this._food = value;
    this.servingSizes = this.createServingSizeOptions(value);
    if (!this.selectedServingSize) this.selectedServingSize = this.servingSizes[0];

    this.energy = this.food.energy;
    this.nutrients = { ...value.nutrients };
  }

  servingSizes: ServingSizeOption[] = [];
  selectedServingSize!: ServingSizeOption;

  energy!: Quantity<Energy>;
  nutrients!: Omit<Nutrients, 'toDto'>;

  openSourceUrl() {
    window.open(this.food.source, '_blank');
  }

  onServingSizeSelected(measurement: Measurement<Mass>) {
    const factor = measurement.quantity.value / 100;
    this.energy = calories(this.food.energy.value * factor);

    Object.entries(this.food.nutrients).forEach(([key, qty]: [string, Quantity<Mass>]) => {
      this.nutrients[key as keyof Omit<Nutrients, 'toDto'>] = grams(qty.value * factor);
    });
  }

  private createServingSizeOptions(food: Food) {
    const options = [
      {
        ...new Measurement('Standard', grams(100)),
        display: '100 g',
      },
      ...food.servingSizes.map((ss) => {
        return {
          ...ss,
          display: `${ss.name} (${ss.quantity.value} g)`,
        };
      }),
    ];

    return options;
  }
}
