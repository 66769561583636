<div class="flex justify-center h-72 mt-4">
  <div class="flex flex-col grow justify-center items-center">
    <button mat-fab color="primary" (click)="decrement()">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>

  <div class="flex flex-col justify-end">
    <div class="w-[160px] h-[245px] bg-[url('/assets/water-glass-w-160.png')] bg-no-repeat">
      <div class="flex h-full justify-center items-center text-3xl text-gray-700">
        {{ waterQuantity$ | async }}
      </div>
    </div>
  </div>

  <div class="flex flex-col grow justify-center items-center">
    <button mat-fab color="accent" (click)="increment()">
      <mat-icon>expand_less</mat-icon>
    </button>
  </div>
</div>
