import { Profile } from '@lean-life/models';

export class SetLoggedIn {
  static readonly type = '[AppState] SetLoggedIn';
  constructor(public readonly loggedIn: boolean) {}
}

export class SetProfile {
  static readonly type = '[AppState] SetProfile';
  constructor(public readonly profile: Profile) {}
}

export class ClearProfile {
  static readonly type = '[AppState] ClearProfile';
}

export class ToggleSidenav {
  static readonly type = '[AppState] ToggleSidenav';
}
