import { isDate, isString } from '@lean-life/common';
import { IsoDate, UUID } from './utility-types';

export function toIsoDate(date: Date | IsoDate) {
  if (isDate(date)) {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  if (isString(date) && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date;
  }

  throw new Error("Value is not a Date, or a string with format 'yyyy-mm-dd'.");
}

export function getId(userId: UUID, date: Date | IsoDate) {
  const isoDate = toIsoDate(date);
  return `${userId}-${isoDate}`;
}

export function toLongDate(date: Date | IsoDate) {
  const realDate = isDate(date) ? date : new Date(date);

  return new Intl.DateTimeFormat('en-ZA', {
    dateStyle: 'full',
  }).format(realDate);
}
