import { QuantityDefinition } from './quantity-types';

export type VolumeType = {
  name: 'Volume';
  baseUnit: 'm³';
  unitKeys: 'l' | 'ml' | 'tsp' | 'cup';
};

export type Volume = VolumeType['name'];

export const volumeDefinition: QuantityDefinition<Volume> = {
  name: 'Volume',
  baseUnit: {
    symbol: 'm³',
    singularName: 'cubic meter',
    pluralName: 'cubic meters',
  },
  units: {
    l: {
      symbol: 'l',
      singularName: 'litre',
      pluralName: 'litres',
      fromUnitToBaseFunc: (x: number): number => x / 1e3,
      fromBaseToUnitFunc: (x: number): number => x * 1e3,
    },
    ml: {
      symbol: 'ml',
      singularName: 'millilitre',
      pluralName: 'millilitres',
      fromUnitToBaseFunc: (x: number): number => x / 1e6,
      fromBaseToUnitFunc: (x: number): number => x * 1e6,
    },
    tsp: {
      symbol: 'tsp',
      singularName: 'metric teaspoon',
      pluralName: 'metric teaspoons',
      fromUnitToBaseFunc: (x: number): number => x * 0.000005,
      fromBaseToUnitFunc: (x: number): number => x / 0.000005,
    },
    cup: {
      symbol: 'cup',
      singularName: 'metric cup',
      pluralName: 'metric cup',
      fromUnitToBaseFunc: (x: number): number => x * 0.00025,
      fromBaseToUnitFunc: (x: number): number => x / 0.00025,
    },
  },
};
