<div class="max-h-[calc(100vh-400px)] overflow-y-auto">
  <lean-meal [mealtime]="mealtime.Breakfast"></lean-meal>
  <lean-meal [mealtime]="mealtime.MidMorning"></lean-meal>
  <lean-meal [mealtime]="mealtime.Lunch"></lean-meal>
  <lean-meal [mealtime]="mealtime.MidAfternoon"></lean-meal>
  <lean-meal [mealtime]="mealtime.Dinner"></lean-meal>
  <lean-meal [mealtime]="mealtime.LateNight"></lean-meal>
  <lean-meal [mealtime]="mealtime.Anytime"></lean-meal>
</div>

<div
  class="flex justify-end items-center h-12 sticky bottom-0 border-t border-t-neutral-600 border-b border-b-neutral-600"
>
  <div class="grow h-full px-4 py-2 text-lg text-end align-middle">Daily totals:</div>
  <div class="h-full w-20 px-4 py-2 text-lg text-end align-middle">
    {{ (currentEntry$ | async)?.energy?.value | number : '1.0-0' }}
  </div>
  <div class="h-full w-20 px-4 py-2 text-lg text-end align-middle">
    {{ (currentEntry$ | async)?.nutrients?.protein?.value | number : '1.2-2' }}
  </div>
  <div class="h-full w-20 px-4 py-2 text-lg text-end align-middle">
    {{ (currentEntry$ | async)?.nutrients?.carbs?.value | number : '1.2-2' }}
  </div>
  <div class="h-full w-20 px-4 py-2 text-lg text-end align-middle">
    {{ (currentEntry$ | async)?.nutrients?.fat?.value | number : '1.2-2' }}
  </div>
  <div class="h-full w-36"></div>
</div>
