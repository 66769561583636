import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { JournalEntry, MealTime } from '@lean-life/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { JournalState } from '../journal.state';
import { MealComponent } from '../meal/meal.component';

@UntilDestroy()
@Component({
  selector: 'lean-nutrition-log',
  standalone: true,
  imports: [CommonModule, MealComponent, MatDividerModule],
  templateUrl: './nutrition-log.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutritionLogComponent {
  mealtime = MealTime;

  @Select(JournalState.current) currentEntry$!: Observable<JournalEntry>;
}
