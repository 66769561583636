import { IsoDate, JournalEntry, Meal, Quantity, Volume } from '@lean-life/models';

export class SetJournalEntry {
  static readonly type = '[JournalState] SetJournalEntry';
  constructor(public readonly journalEntry: JournalEntry) {}
}

export class SetCurrentDate {
  static readonly type = '[JournalState] SetCurrentDate';
  constructor(public readonly currentDate: IsoDate) {}
}

export class SetWater {
  static readonly type = '[JournalState] SetWater';
  constructor(public readonly quantity: Quantity<Volume>) {}
}
export class UpdateMeal {
  static readonly type = '[JournalState] UpdateMeal';
  constructor(public readonly meal: Meal) {}
}
