import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lean-locker-room',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './locker-room.component.html',
  styleUrls: ['./locker-room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockerRoomComponent {}
