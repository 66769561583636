import { Food, UUID } from '@lean-life/models';

export class SetFood {
  static readonly type = '[FoodState] SetFood';
  constructor(public readonly food: Food) {}
}

export class SetFoods {
  static readonly type = '[FoodState] SetFoods';
  constructor(public readonly foods: Food[]) {}
}

export class RemoveFood {
  static readonly type = '[FoodState] RemoveFood';
  constructor(public readonly id: UUID) {}
}
