import { QuantityDefinition } from './quantity-types';

export type EnergyType = {
  name: 'Energy';
  baseUnit: 'j';
  unitKeys: 'kj' | 'cal' | 'kcal';
};

export type Energy = EnergyType['name'];

export const energyDefinition: QuantityDefinition<Energy> = {
  name: 'Energy',
  baseUnit: {
    symbol: 'j',
    singularName: 'joule',
    pluralName: 'joules',
  },
  units: {
    kj: {
      symbol: 'kj',
      singularName: 'kilojoule',
      pluralName: 'kilojoles',
      fromUnitToBaseFunc: (x: number): number => x * 1e3,
      fromBaseToUnitFunc: (x: number): number => x / 1e3,
    },
    cal: {
      symbol: 'cal',
      singularName: 'calorie',
      pluralName: 'calories',
      fromUnitToBaseFunc: (x: number): number => x * 4.184,
      fromBaseToUnitFunc: (x: number): number => x / 4.184,
    },
    kcal: {
      symbol: 'kcal',
      singularName: 'kilocalorie',
      pluralName: 'kilocalories',
      fromUnitToBaseFunc: (x: number): number => x * 4.184e3,
      fromBaseToUnitFunc: (x: number): number => x / 4.184e3,
    },
  },
};
