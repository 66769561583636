export class DialogData {
  constructor(
    public title: string,
    public message: string,
    public okButton: DialogButton = OK_BUTTON,
    public cancelButton: DialogButton | null = null,
    public disableClose: boolean = false
  ) {}
}

export type ButtonColor = 'primary' | 'accent' | 'warn';

export type DialogButton = {
  text: string;
  color?: ButtonColor;
};

export const OK_BUTTON: DialogButton = {
  text: 'Ok',
  color: 'primary',
};

export const CANCEL_BUTTON: DialogButton = {
  text: 'Cancel',
  color: 'accent',
};
