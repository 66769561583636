import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Food, ServingSize, standardServingSizes } from '@lean-life/models';

@Component({
  selector: 'lean-serving-size-picker',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatSelectModule],
  templateUrl: './serving-size-picker.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServingSizePickerComponent {
  private element: HTMLElement = inject(ElementRef).nativeElement;
  classList: string;

  @Input() set food(value: Food) {
    this.servingSizes = [...standardServingSizes, ...value.servingSizes];
    if (!this.selectedServingSize) this.selectedServingSize = this.servingSizes[0];
  }

  @Input() set servingSize(value: ServingSize) {
    this.selectedServingSize = value ?? this.servingSizes[0];
  }

  @Output() servingSizeSelected = new EventEmitter<ServingSize>();

  constructor() {
    this.classList = this.element.classList.toString();
  }

  servingSizes: ServingSize[] = [];
  selectedServingSize!: ServingSize;

  onSelectionChange(change: MatSelectChange) {
    this.servingSizeSelected.emit(change.value);
  }
}
