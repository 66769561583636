import { Injectable, inject } from '@angular/core';
import { isNil, isString } from '@lean-life/common';
import { IsoDate, JournalEntry, toIsoDate } from '@lean-life/models';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { filter, switchMap, take } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { SetCurrentDate, SetJournalEntry, SetWater, UpdateMeal } from './journal.actions';
import { JournalService } from './journal.service';

const today = new Date();

interface JournalStateModel {
  entries: JournalEntry[];
  currentDate: IsoDate;
}

@State<JournalStateModel>({
  name: 'journalState',
  defaults: {
    entries: [],
    currentDate: toIsoDate(today),
  },
})
@Injectable()
export class JournalState implements NgxsOnInit {
  private store = inject(Store);
  private journalService = inject(JournalService);

  ngxsOnInit(): void {
    this.store
      .select(AppState.userId)
      .pipe(
        filter((userId): userId is string => isString(userId)),
        switchMap((userId: string) => this.journalService.getOrCreateJournalEntry(userId, today)),
        take(1)
      )
      .subscribe();
  }

  @Selector() static current(state: JournalStateModel) {
    return state.entries.find((entry) => entry.date === toIsoDate(state.currentDate || today));
  }

  @Selector() static today(state: JournalStateModel) {
    return state.entries.find((entry) => entry.date === toIsoDate(today));
  }

  static journalEntryByDate(date: IsoDate | Date) {
    return createSelector([JournalState], (state: JournalStateModel) => {
      return state.entries.find((entry) => entry.date === toIsoDate(date));
    });
  }

  @Action(SetJournalEntry) setJournalEntry(
    ctx: StateContext<JournalStateModel>,
    { journalEntry }: SetJournalEntry
  ) {
    const existing = ctx.getState().entries.find((entry) => entry.date === journalEntry.date);

    if (existing) {
      ctx.setState(
        patch<JournalStateModel>({
          entries: updateItem<JournalEntry>((je) => je.date === journalEntry.date, journalEntry),
        })
      );
    } else {
      ctx.setState(
        patch<JournalStateModel>({
          entries: append<JournalEntry>([journalEntry]),
        })
      );
    }
  }

  @Action(SetCurrentDate) setCurrentDate(
    ctx: StateContext<JournalStateModel>,
    { currentDate }: SetCurrentDate
  ) {
    ctx.patchState({ currentDate });
  }

  @Action(SetWater) setWater(ctx: StateContext<JournalStateModel>, { quantity }: SetWater) {
    const state = ctx.getState();
    const current = state.entries.find((entry) => entry.date === state.currentDate);
    if (isNil(current)) return;

    ctx.setState(
      patch<JournalStateModel>({
        entries: updateItem<JournalEntry>((je) => je.date === current.date, {
          ...current,
          water: quantity.convertTo('ml'),
        } as JournalEntry),
      })
    );
  }

  @Action(UpdateMeal) updateMeal(ctx: StateContext<JournalStateModel>, { meal }: UpdateMeal) {
    const current = JournalState.current(ctx.getState());
    if (isNil(current)) throw new Error('Could not find current JournalEntry in state.');
    const index = current.meals.findIndex((m) => m.mealTime === meal.mealTime);

    if (index >= 0) {
      current.meals[index] = meal;
    } else {
      current.meals.push(meal);
    }

    ctx.setState(
      patch<JournalStateModel>({
        entries: updateItem<JournalEntry>((je) => je.date === current.date, current),
      })
    );
  }
}
