import { UnitOf } from '../quantities';
import { QuantityName } from '../quantities/quantity-names';
import { UUID } from '../utilities';

// @CosmosPartitionKey('userId')
export class Metrics {
  /** userId */
  id!: UUID;
  userId!: UUID;
  items: Metric<QuantityName>[] = [];
}

export class Metric<T extends QuantityName> {
  /** app assigned id */
  id!: string;
  ordinal?: number;

  name!: string;
  type!: T;
  unit!: UnitOf<T>;

  /** system metrics are pre-populated and cannot be deleted */
  system?: boolean;
}
