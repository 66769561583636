import { energyDefinition } from './energy';
import { lengthDefinition } from './length';
import { massDefinition } from './mass';
import { QuantityName } from './quantity-names';
import { QuantityDefinition } from './quantity-types';
import { timeDefinition } from './time';
import { unitlessDefinition } from './unitless';
import { volumeDefinition } from './volume';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const quantityDefs: { [Key in QuantityName]: QuantityDefinition<any> } = {
  Energy: energyDefinition,
  Length: lengthDefinition,
  Mass: massDefinition,
  Time: timeDefinition,
  Unitless: unitlessDefinition,
  Volume: volumeDefinition,
};
