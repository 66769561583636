import { Measurement, MeasurementDto } from '../quantities/measurement';
import { QuantityName } from '../quantities/quantity-names';
import { UUID, UserId_IsoDate } from '../utilities';

// @CosmosPartitionKey('userId')
export class BodyStatsDto {
  id!: UserId_IsoDate;
  userId!: UUID;
  dateTime!: Date;
  measurements: MeasurementDto<QuantityName>[] = [];
}

export class BodyStats {
  constructor(
    public readonly id: UserId_IsoDate,
    public readonly userId: UUID,
    public readonly dateTime: Date
  ) {}
  readonly measurements: Measurement<QuantityName>[] = [];

  static fromDto(dto: BodyStatsDto): BodyStats {
    const model = new BodyStats(dto.id, dto.userId, dto.dateTime);
    model.measurements.push(...dto.measurements.map((m) => Measurement.fromDto(m)));
    return model;
  }

  toDto(): BodyStatsDto {
    return {
      id: this.id,
      userId: this.userId,
      dateTime: this.dateTime,
      measurements: this.measurements.map((m) => m.toDto()),
    };
  }
}
