import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Food } from '@lean-life/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FoodState } from '../food.state';

@UntilDestroy()
@Component({
  selector: 'lean-food-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
  ],
  templateUrl: './food-table.component.html',
  styleUrls: ['./food-table.component.scss'],
})
export class FoodTableComponent implements AfterViewInit {
  @Select(FoodState.allFoods) food$!: Observable<Food[]>;

  @Input() set filter(value: string | null) {
    this.dataSource.filter = value ?? '';
  }

  @Output() refreshClick = new EventEmitter<void>();
  @Output() addClick = new EventEmitter<void>();

  @Output() eatClick = new EventEmitter<Food>();
  @Output() editClick = new EventEmitter<Food>();
  @Output() deleteClick = new EventEmitter<Food>();
  @Output() rowClick = new EventEmitter<Food>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<Food>;

  dataSource = new MatTableDataSource<Food>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['description', 'brand', 'energy', 'protein', 'carbs', 'fat', 'actions'];

  constructor() {
    this.food$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.dataSource.data = data;
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onRefreshClick() {
    this.refreshClick.emit();
  }

  onAddClick() {
    this.addClick.emit();
  }

  onEatClick(food: Food) {
    this.eatClick.emit(food);
  }

  onEditClick(food: Food) {
    this.editClick.emit(food);
  }

  onDeleteClick(food: Food) {
    this.deleteClick.emit(food);
  }

  onRowClick(food: Food) {
    this.rowClick.emit(food);
  }
}
