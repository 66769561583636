import { QuantityDefinition } from './quantity-types';

export type MassType = {
  name: 'Mass';
  baseUnit: 'kg';
  unitKeys: 'g' | 'mg' | 'µg';
};

export type Mass = MassType['name'];

export const massDefinition: QuantityDefinition<Mass> = {
  name: 'Mass',
  baseUnit: {
    symbol: 'kg',
    singularName: 'kilogram',
    pluralName: 'kilograms',
  },
  units: {
    g: {
      symbol: 'g',
      singularName: 'gram',
      pluralName: 'grams',
      fromUnitToBaseFunc: (x: number): number => x / 1e3,
      fromBaseToUnitFunc: (x: number): number => x * 1e3,
    },
    mg: {
      symbol: 'mg',
      singularName: 'milligram',
      pluralName: 'milligrams',
      fromUnitToBaseFunc: (x: number): number => x / 1e6,
      fromBaseToUnitFunc: (x: number): number => x * 1e6,
    },
    µg: {
      symbol: 'µg',
      singularName: 'microgram',
      pluralName: 'micrograms',
      fromUnitToBaseFunc: (x: number): number => x / 1e9,
      fromBaseToUnitFunc: (x: number): number => x * 1e9,
    },
  },
};
