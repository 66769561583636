import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Profile } from '@lean-life/models';
import { Store } from '@ngxs/store';
import { Observable, zip } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ClearProfile, SetLoggedIn, SetProfile } from '../app-state/app.actions';
import { readAsDataURL } from '../utilities/rx-js-file-reader';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  handleRedirect$: Observable<AuthenticationResult>;

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private store: Store
  ) {
    const loggedIn$ = this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => {
        return this.msalService.instance.getAllAccounts().length > 0;
      })
    );

    loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn),
        switchMap(() => this.getProfileWithImage()),
        switchMap((profile) => {
          return this.store.dispatch([new SetLoggedIn(true), new SetProfile(profile)]);
        })
      )
      .subscribe();

    loggedIn$
      .pipe(
        filter((loggedIn) => !loggedIn),
        switchMap(() => this.store.dispatch([new SetLoggedIn(false), ClearProfile]))
      )
      .subscribe();

    this.handleRedirect$ = this.msalService.handleRedirectObservable();
  }

  login(): Observable<void> {
    return this.msalService.loginRedirect();
  }

  logout(): Observable<void> {
    return this.msalService.logout();
  }

  getProfile(): Observable<Profile> {
    return this.http.get<Profile>('https://graph.microsoft.com/v1.0/me');
  }

  getProfileImage(): Observable<string> {
    return this.http
      .get('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', { responseType: 'blob' })
      .pipe(switchMap(readAsDataURL));
  }

  getProfileWithImage(): Observable<Profile> {
    return zip(this.getProfile(), this.getProfileImage()).pipe(
      map(([profile, image]) => {
        profile.image = image;
        return profile;
      })
    );
  }
}
