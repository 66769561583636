import { AppSettings } from './app-settings';

export const appSettings: AppSettings = {
  authSettings: {
    clientId: '938fb379-6eb4-41ba-bcbe-7d0c77c7b05f',
    authority: 'https://login.microsoftonline.com/03e7f739-66f8-44a4-9183-c94d59ab2bcf', // tenantId
    redirectUri: 'https://leanlife.app/',
    postLogoutRedirectUri: 'https://leanlife.app/',
    navigateToLoginRequestUrl: true,
  },
  apiEndpoint: 'https://lean-func-app.azurewebsites.net',
};
