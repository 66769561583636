import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { isDefined } from '@lean-life/common';
import {
  JournalEntry,
  JournalEntryDto,
  UUID,
  toIsoDate
} from '@lean-life/models';
import { Store } from '@ngxs/store';
import { Observable, combineLatestWith, filter, map, of, switchMap } from 'rxjs';
import { appSettings } from '../../settings/settings';
import { FoodState } from '../food/food.state';
import { SetJournalEntry } from './journal.actions';

@Injectable({ providedIn: 'root' })
export class JournalService {
  private http = inject(HttpClient);
  private store = inject(Store);
  private endpoint = appSettings.apiEndpoint;

  getOrCreateJournalEntry(userId: UUID, date: Date): Observable<JournalEntry> {
    const isoDate = toIsoDate(date);

    return this.http.get<JournalEntryDto>(`${this.endpoint}/api/journal/${userId}/${isoDate}`).pipe(
      switchMap((dto) => {
        if (dto) return of(dto);

        const entry = new JournalEntry(userId, date);
        const body = entry.toDto();
        return this.http.post<JournalEntryDto>(`${this.endpoint}/api/journal`, body);
      }),
      combineLatestWith(this.store.select(FoodState.allFoods)),
      filter(([dto, foods]) => isDefined(dto) && isDefined(foods)),
      switchMap(([dto, foods]) => {
        const entry = JournalEntry.fromDto(dto, foods);
        return this.store.dispatch(new SetJournalEntry(entry)).pipe(map(() => entry));
      })
    );
  }

  saveJournalEntry(entry: JournalEntry) {
    const body = entry.toDto();

    return this.http
      .put<JournalEntryDto>(`${this.endpoint}/api/journal/${entry.userId}/${entry.date}`, body)
      .pipe(
        combineLatestWith(this.store.select(FoodState.allFoods)),
        filter(([dto, foods]) => isDefined(dto) && isDefined(foods)),
        switchMap(([dto, foods]) => {
          const entry = JournalEntry.fromDto(dto, foods);
          return this.store.dispatch(new SetJournalEntry(entry)).pipe(map(() => entry));
        })
      );
  }

}
