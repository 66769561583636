import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { DialogComponent } from './dialog.component';
import { DialogData } from './dialog.model';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private dialog: MatDialog = inject(MatDialog);

  show(data: DialogData): Observable<boolean> {
    const dialogRef = this.dialog.open<DialogComponent, DialogData, boolean>(DialogComponent, {
      minWidth: '400px',
      disableClose: data.disableClose,
      autoFocus: false,
      data,
    });

    return dialogRef.afterClosed().pipe(map((confirmed) => confirmed ?? false));
  }
}
