import { Energy } from './energy';
import { Length } from './length';
import { Mass } from './mass';
import { Quantity } from './quantity';
import { UnitOf } from './quantity-types';
import { Time } from './time';
import { Unitless } from './unitless';
import { Volume } from './volume';

export function energy(value: number, unit: UnitOf<Energy>) {
  return new Quantity('Energy', value, unit);
}

export function length(value: number, unit: UnitOf<Length>) {
  return new Quantity('Length', value, unit);
}

export function mass(value: number, unit: UnitOf<Mass>) {
  return new Quantity('Mass', value, unit);
}

export function time(value: number, unit: UnitOf<Time>) {
  return new Quantity('Time', value, unit);
}

export function unitless(value: number, unit: UnitOf<Unitless> = '') {
  return new Quantity('Unitless', value, unit);
}

export function volume(value: number, unit: UnitOf<Volume>) {
  return new Quantity('Volume', value, unit);
}

export function calories(value = 0) {
  return energy(value, 'kcal');
}

export function caloriesDto(value = 0) {
  return calories(value).toDto();
}

export function grams(value = 0) {
  return mass(value, 'g');
}

export function gramsDto(value = 0) {
  return grams(value).toDto();
}
