import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { Profile } from '@lean-life/models';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, take } from 'rxjs';
import { ToggleSidenav } from './app-state/app.actions';
import { AppState } from './app-state/app.state';
import { AuthService } from './auth/auth.service';
import { ProfileComponent } from './auth/profile/profile.component';
import { isIframe } from './utilities/utils';

@Component({
  selector: 'lean-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    ProfileComponent,
  ],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  isIframe = false;

  @Select(AppState.loggedIn) loggedIn$!: Observable<boolean>;
  @Select(AppState.profile) profile$!: Observable<Profile>;
  @Select(AppState.sidenavOpen) sidenavOpen$!: Observable<boolean>;

  showRouterOutlet$!: Observable<boolean>;

  constructor(private authService: AuthService, private router: Router, private store: Store) {
    this.isIframe = isIframe();
    this.setupShowRouterOutletSource();
  }

  toggleSidenav(): void {
    this.store.dispatch(ToggleSidenav);
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  private setupShowRouterOutletSource() {
    this.showRouterOutlet$ = this.sidenavOpen$.pipe(
      filter((open) => open),
      take(1)
    );
  }
}
