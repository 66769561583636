/** Retrieved from Microsoft Graph API */
export interface Profile {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
  mobilePhone: string;

  businessPhones: string[];
  jobTitle: string;
  officeLocation: string;
  preferredLanguage: string;
  userPrincipalName: string;
  '@odata.context': string;

  /** data: url */
  image?: string;
}
