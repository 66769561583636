import { Energy, Mass, Quantity, QuantityDto, energy, mass } from '../quantities';
import { Food } from './food';
import { Nutrients, NutrientsDto } from './nutrients';
import { ServingSize, hundredGrams, oneGram, standardServingSizes } from './serving-size';

export interface MealItemDto {
  ordinal: number;
  foodId: string;
  portion: number;
  servingSize: string;
  energy: QuantityDto<Energy>;
  nutrients: NutrientsDto;
}

export class MealItem {
  private _food: Food;
  private _portion = 0;
  private _servingSize = hundredGrams;

  get food(): Food {
    return this._food;
  }

  set food(value: Food) {
    this._food = value;
    this.calculate();
  }

  get portion(): number {
    return this._portion;
  }

  set portion(value: number) {
    this._portion = value;
    this.calculate();
  }

  get servingSize(): ServingSize {
    return this._servingSize;
  }

  set servingSize(value: ServingSize) {
    this._servingSize = value;
    this.calculate();
  }

  energy = energy(0, 'kcal');
  nutrients = new Nutrients();

  constructor(public ordinal: number, food: Food) {
    this._food = food;
    this.calculate();
  }

  calculate() {
    const base = hundredGrams.quantity;
    const quantityOfBase = this._servingSize.quantity.convertTo(base.unit);
    const portionOfBase = this.portion * quantityOfBase.value;
    const factor = portionOfBase / base.value;
    const energyValue = factor * this.food.energy.value;

    this.energy = energy(energyValue, 'kcal');

    Object.entries(this.food.nutrients)
      .filter(([, value]) => value instanceof Quantity)
      .forEach(([key, value]: [string, Quantity<Mass>]) => {
        const qtyValue = factor * value.value;
        if (this.nutrients[key as keyof NutrientsDto] instanceof Quantity) {
          this.nutrients[key as keyof NutrientsDto] = mass(qtyValue, base.unit);
        }
      });
  }

  clone() {
    const item = new MealItem(this.ordinal, this.food);
    item._portion = this._portion;
    item._servingSize = this._servingSize;
    return item;
  }

  update(clone: MealItem) {
    this._food = clone.food;
    this._portion = clone.portion;
    this._servingSize = clone.servingSize;
    this.calculate();
  }

  static fromDto(dto: MealItemDto, food: Food): MealItem {
    const model = new MealItem(dto.ordinal, food);
    const servingSize =
      [...standardServingSizes, ...food.servingSizes].find((ss) => ss.name === dto.servingSize) ??
      oneGram;

    model.portion = dto.portion;
    model.servingSize = servingSize;
    model.energy = Quantity.fromDto(dto.energy);
    model.nutrients = Nutrients.fromDto(dto.nutrients);
    return model;
  }

  toDto(): MealItemDto {
    return {
      foodId: this.food.id,
      ordinal: this.ordinal,
      portion: this.portion,
      servingSize: this.servingSize.name,
      energy: this.energy.toDto(),
      nutrients: this.nutrients.toDto(),
    };
  }
}
