<mat-form-field subscriptSizing="dynamic" [ngClass]="classList">
  <mat-label>Serving Size</mat-label>
  <mat-select
    [(value)]="selectedServingSize"
    (selectionChange)="onSelectionChange($event)"
    class="!text-sm"
  >
    <mat-option *ngFor="let servingSize of servingSizes" [value]="servingSize"
      >{{ servingSize.display }}
    </mat-option>
  </mat-select>
</mat-form-field>
